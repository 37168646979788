import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TitleCasePipe} from '@angular/common';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../services/form.service';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {Api, Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {isObject} from 'util';
// import {UserModulePermission} from '../../models/user-module-permission';
import { UserService } from 'src/app/services/user.service';
import {Observable} from "rxjs";

@Component({
  selector: 'app-edit-user-permission',
  templateUrl: './edit-user-permission.component.html',
  styleUrls: ['./edit-user-permission.component.css']
})
export class EditUserPermissionComponent implements OnInit {
  _formErrors: any = {};
  form: FormGroup;
  user_id: string;
  user_role: string;
  user_roles: Observable<any>;

  @ViewChild('role') titleField: ElementRef;

  constructor(private titlecase: TitleCasePipe,private users: UserService, private data: DataService, private route: ActivatedRoute,
              private formService: FormService, private router: Router) {
  }


  ngOnInit() {
    // @ts-ignore

    var current_url = this.router.url;
    this.user_id = current_url.split('/').pop().split('&')[0];
    this.user_role = current_url.split('&')[1];
    
    if(this.users.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
      this.getUserRoles();
      this._resetFormErrors();
      this.form = new FormGroup({
        role: new FormControl(null, [Validators.required]),
        modules: new FormControl(null, []),
      });
      // this.initializeImage();
    }
  }
  private _resetFormErrors(): void {
    
  }

  formReset() {
    this.router.navigate(['/' + Route.module]);
  }


  getUserRoles(){
    this.data.getUserRoles().subscribe((response: ServerResponse) => {
      console.log('response', response);
      if (response.error === 0 && response.authError === 0) {
        this.user_roles = response.data.userRoleList;
      }
    },
    error => {
      console.log('error meassge ', error.message);
    });
  }

  updateUserPermission() {
    if (!this.form.invalid) {
      let data_params = {
        'userId': this.user_id,
        'roleId': this.titleField.nativeElement.value,
        'role': this.titleField.nativeElement.options[this.titleField.nativeElement.options.selectedIndex].text
      }
      
      this.data.putuserroleUser(data_params).subscribe((response: ServerResponse) => {
        
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'User Role is Updated Successfully',
            closeOnClickOutside : false,
            icon: 'success'
          });
          this.router.navigate(['/' + Route.users]).catch(reason => {
            console.log('reason', reason);
            this.formReset();
          });
        } else if (response.error > 0) {
          if (isObject(response.msg)) {
            for (const msgKey in response.msg) {
              if (response.msg.hasOwnProperty(msgKey)) {
                this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
              }
            }
          } else {
            // this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
          }
        }
      },
      error => {
        console.log('error meassge ', error.message);
      });

    } else {
      swal({
        title: 'Message',
        text: 'Oops, User Role is not selected, Please select User Role to proceed',
        icon: 'warning'
      });
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

}
