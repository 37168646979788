import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator} from '@angular/material';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {DataService} from '../../services/data.service';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent implements OnInit {


  @ViewChild(MatSort) sort: MatSort;
   @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource;
  displayColumns = ['id', 'title', 'active', 'action'];
  appRouts = Route;
  searchFor: string;
  constructor(private data: DataService, private user: UserService, private router: Router) {}

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getModules().subscribe(data => {
      if (data.error === 0 && data.authError === 0) {
        if (!data) {
          return;
        }
        this.dataSource = new MatTableDataSource(data.data.moduleList);
		this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else if (data.error === 1 && data.authError === 1) {
        localStorage.clear();
        this.router.navigate(['/' + Route.login]);
      }
      });
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilter() {
    this.dataSource.filter = null;
    this.searchFor = '';
  }

  deleteModule(event, id){
    this.data.deleteModule({id: id}).subscribe((response: ServerResponse) => {
      console.log('response', response);
      if (response.error === 0 && response.authError === 0) {
        swal({
          title: 'Message',
          text: 'Module deleted successfully',
          icon: 'success'
        });
        this.router.navigate(['/' + Route.module]);
      } else if (response.error > 0) {
        swal({
          title: 'Message',
          text: 'There is problem in deleting an module.',
          icon: 'success'
        });
      }
});
  }

}
