import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Route } from '@angular/router';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.css']
})
export class IconsComponent implements OnInit {

  constructor(public user: UserService) { }

  ngOnInit() {
  }

}
