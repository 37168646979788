import {Page} from './page';
import {Category} from './category';

export class Story {
  id: number;
  title: string;
  price: number;
  isActive: number;
  thumbnail: string;
  pace :number;
  productId :string
  subscriptionType : number;
  page: Page[];
  public category: string [] = [];
  public tags: string [] = [];
  description: string;

}
