import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Route} from '../../utils/constant';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TitleCasePipe} from '@angular/common';
import {FormService} from '../../services/form.service';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {isObject} from 'util';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  // tutorialUrlText: string;
  _formErrors: any = {};
  form: FormGroup;
  tutorialData = {
    tutorialUrlText: ''
  };
  @ViewChild('tutorialUrl') tutorialUrlField: ElementRef;

  constructor(private titlecase: TitleCasePipe,public user: UserService, private router: Router, private dataService: DataService, private formService: FormService) {
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.dataService.getTutorial().subscribe((data: ServerResponse) => {
      if (!data) {
        return;
      }
      this.tutorialData.tutorialUrlText = data.data.text;

    });
    this._resetFormErrors();
    this.form = new FormGroup({
      tutorialUrl: new FormControl(null, [Validators.required])
    });
  }
}

  private _resetFormErrors(): void {
    for (const credentialKey in this.tutorialData) {
      if (this.tutorialData.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }

  formReset() {
    this.router.navigate(['/' + Route.default]);
  }

  putTutorial() {
    console.log('putTutorial');
    if (!this.form.invalid) {
      this.dataService.putTutorial({text: this.tutorialData.tutorialUrlText}).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Video Tutorial URL is Updated Successfully',
              icon: 'success'
            });
            this.router.navigate(['/' + Route.default]).catch(reason => {
              console.log('reason', reason);
            });
          } else if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              this.form.get('tutorialUrl').setErrors({server: true, msg: response.msg});
            }
          }
        },
        error => {
          console.log('error message ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      this.tutorialUrlField.nativeElement.focus();
      return false;
    }
  }

  hasError(field): boolean {
    console.log('form field', this.form.get(field));
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }
}
