import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from "rxjs";
import {Route, Token} from '../utils/constant';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem(Token.userToken) !== null) {
      return true;
    }
    this.router.navigate(['/' + Route.login]);
    return false;
  }
 
}

@Injectable()
export class AnonymousAuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem(Token.userToken) === null) {
      return true;
    }
    this.router.navigate(['/' + Route.default]);
    return false;
  }
}

@Injectable()
export class AppUserAuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem(Token.userToken) !== null) {
      localStorage.removeItem(Token.userToken);
    }
    return true;
  }
}

