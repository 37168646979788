import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Page, PAGE_TYPE} from '../../models/page';
import {ServerResponse} from '../../models/server-response.model';
import {isObject} from 'util';
import {DataService} from '../../services/data.service';
import swal from 'sweetalert';
import {Api, SERVER_IMAGE_PATH, Route} from '../../utils/constant';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  pagefileToUpload1: File = null;
  imageUrl = 'assets/img/default-image.png';
  msgValue="";
  @Output() itemDeleted = new EventEmitter<{ index: number }>();
  @Output() addPage = new EventEmitter<Page>();
  @Input() indexno;
  @Input() pageData;
  @Input() delPageData;
  indexNo1: number;
  width: number;
  height: number;
  page = new Page();
  @ViewChild('imageFocusField') imageFocusField: ElementRef;

  savePage() {
    let compatible_chk = this.isUploadImageCompatible();
    if(compatible_chk == "OK"){
      console.log('index in emit', this.indexno);
      this.page.index = this.indexno;
      this.indexNo1 = this.indexno;
      // this.page.image = this.imageUrl;
      console.log('this.page', this.page);
      this.addPage.emit(this.page);
    } else {
      swal({
        title: 'Message',
        text: compatible_chk,
        icon: 'warning'
      });
    }
  }

  constructor(private data: DataService,public user: UserService, private router: Router) {
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    console.log('page IndexNo', this.indexno);
    this.indexNo1 = this.indexno;
    this.imageUrl = 'assets/img/default-image.png';
    if (this.pageData && Object.keys(this.pageData).length) {
      console.log('delPageData', this.pageData.image);
      console.log('pageData', this.pageData);
      this.page = this.pageData;
      this.imageUrl = this.page.image!=null?this.page.image:this.imageUrl;
      if(this.page.image!=null){
        this.urlReplace();
      }
    }else {
      this.page.type=PAGE_TYPE.TEXT_IMAGE;
    }
    console.log('delPageData', this.delPageData);
  }
}

  pagehandleFileInput(file1: FileList) {
    this.pagefileToUpload1 = file1.item(0);
    if (this.pagefileToUpload1.size / 1024 / 1024 > 1) {
      swal({
        title: 'Message',
        text: 'File Size cannot be more than 1MB',
        icon: 'warning'
      });
    } else {
      // when file has loaded
      const reader = new FileReader();
      reader.readAsDataURL(this.pagefileToUpload1);
      swal({
        title: 'Please Wait...',
        text: 'Image is uploading....',
        icon: 'warning',
        timer: 1500 ,
        buttons: [
          false , false
        ]
      });
      reader.onload = (event: any) => {
      const img = new Image();
      img.onload = () => {
      this.width = img.width;
      this.height = img.height;

      // console.log('this.width', this.width);
      // console.log('this.height', this.height);
      // console.log(PAGE_TYPE.IMAGE);
      // console.log(this.page.type);
        // tslint:disable-next-line:max-line-length

      if (this.width === 700 && this.height === 300 && this.page.type === PAGE_TYPE.TEXT_IMAGE || this.width === 700 && this.height === 500 && this.page.type === PAGE_TYPE.TEXT_IMAGE2S || this.width === 700 && this.height === 700 && this.page.type === PAGE_TYPE.TEXT_IMAGE3S || this.page.type== PAGE_TYPE.IMAGE) {
        this.data.postFile(this.pagefileToUpload1).subscribe((response: ServerResponse) => {
            console.log('response', response);
            if (response.error === 0 && response.authError === 0) {
              this.imageUrl = SERVER_IMAGE_PATH + response.data.fileUrl;
              this.page.image = response.data.fileUrl;
              swal({
                title: 'Message',
                text: 'Image has been Uploaded Successfully!',
                icon: 'success'
              });
              swal.stopLoading();
              // swal.close();
            } else if (response.error > 0) {
              if (isObject(response.msg)) {
              } else {
                // this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
              }
            }
          },
          error => {
            console.log('error of file ', error.message);
          });
      } else {
		  this.msgValue="Image must be of 700 X 300";
      
		  if(this.page.type==PAGE_TYPE.TEXT_IMAGE2S){
			this.msgValue="Image must be of 700 X 500";
		  }
		  else if(this.page.type==PAGE_TYPE.TEXT_IMAGE3S){
			  this.msgValue="Image must be of 700 X 700";
		  }
        swal({
          title: 'Message',
          text: this.msgValue,
          icon: 'warning'
        });
      }
    };
        img.src = event.target.result;
        this.page.image = img.src;
      };
    }
  }

  removepagehandleFileInput() {
    this.imageUrl = 'assets/img/default-image.png';
    this.page.image = null;
    this.imageFocusField.nativeElement.value  = '';
  }

  onDeleteItem() {
    this.itemDeleted.emit();
  }

  urlReplace() {
    this.pageData.image = this.pageData.image.replace(SERVER_IMAGE_PATH, '');
    this.pageData.image = this.pageData.image.replace('http:/54.187.103.219/app/public/upload/', '');
  }

  isNotSaveAble(){
    if(this.page.type == PAGE_TYPE.TEXT_IMAGE) {
      return this.imageUrl == '' || this.imageUrl == 'assets/img/default-image.png' || !this.page.content || this.page.content.trim() == '';
    }

	else if(this.page.type == PAGE_TYPE.TEXT_IMAGE2S) {
      return this.imageUrl == '' || this.imageUrl == 'assets/img/default-image.png' || !this.page.content || this.page.content.trim() == '';
    }
	else if(this.page.type == PAGE_TYPE.TEXT_IMAGE3S) {
      return this.imageUrl == '' || this.imageUrl == 'assets/img/default-image.png' || !this.page.content || this.page.content.trim() == '';
    }
	else if(this.page.type == PAGE_TYPE.TEXT){
      return !this.page.content || this.page.content.trim() == '';
    }else if(this.page.type == PAGE_TYPE.IMAGE){
      return this.imageUrl == '' || this.imageUrl == 'assets/img/default-image.png'
    }
    return true;
  }

  getMeta(url){
    const img = new Image();
    img.src = url;
    return img;
  };

  isUploadImageCompatible(){
    let resp = "OK";
    const img = new Image();
    img.src = SERVER_IMAGE_PATH + this.page.image;
    this.width = img.width;
    this.height = img.height;
    
    if (this.page.type === PAGE_TYPE.TEXT_IMAGE || this.page.type === PAGE_TYPE.TEXT_IMAGE2S || this.page.type === PAGE_TYPE.TEXT_IMAGE3S){
      if(this.page.type === PAGE_TYPE.TEXT_IMAGE){
        if (this.width !== 700 || this.height !== 300){
          resp = "Please select the image 700X300 for this page type";
        }
      }
      else if (this.page.type === PAGE_TYPE.TEXT_IMAGE2S){
        if(this.width !== 700 || this.height !== 500){
          resp = "Please select the image 700X500 for this page type";
        }
      }
      else if (this.page.type === PAGE_TYPE.TEXT_IMAGE3S){
        if(this.width !== 700 || this.height !== 700){
          resp = "Please select the image 700X700 for this page type";
        } 
      }
    }
    return resp;
  }

}
