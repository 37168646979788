import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../models/user.model';
import {Utils} from '../utils/utility';
import {Api, Route, Token} from '../utils/constant';
import {ServerResponse} from '../models/server-response.model';
import {Router} from '@angular/router';

@Injectable()
export class UserService {
  constructor(private http: HttpClient,
              private router: Router) {
  }


  login(cred) {
    return this.http.post(Api.login, cred);
  }
  landingpagelogin(cred1) {
    return this.http.post(Api.landingpagelogin, cred1);
  }

  forgot(data) {
    const body = data;
    return this.http.post(Api.forgotPassword, body);
  }

  verifyToken(token) {
    const body: String = '?token=' + token;
    return this.http.get(Api.verifyToken + body);
  }

  resetPassword(data) {
    const body = data;
    return this.http.post(Api.resetPassword, body);
  }

  changePassword(data) {
    const body = data;
    return this.http.put(Api.changePassword, body);
  }

  logoutUser() {
    const body = {};

    // return this.http.post(Api.logout, body).subscribe((response: ServerResponse) => {
    //   Utils.analyseResponse(response);
    //   if (!response.error) {
    localStorage.removeItem(Token.userToken);
    localStorage.removeItem('userSignInEmail');
    localStorage.removeItem('role');
    this.router.navigate(['/' + Route.login]);
    window.location.reload();
    //   }
    // }, (error) => {
    //   console.log(error.message);
    // });
  }
  logoutWebUser() {
    const body = {};
    localStorage.removeItem(Token.webuserToken);
    localStorage.removeItem('webSignInEmail');
  }

  isweblogin() {
    if (localStorage.getItem(Token.webuserToken)) {
      return true;
    } else {
      return false;
    }
  }
  islogin() {
    if (localStorage.getItem(Token.userToken)) {
      return true;
    } else {
      return false;
    }
  }
  getRole() {
    if (localStorage.getItem("role")=="Assistant") {
      return true;
    } else {
      return false;
    }
  }

  getUsersData() {
    return this.http.get(Api.downloadUsersData);
  }
  
  getSalesData() {
    return this.http.get(Api.downloadSalesData);
  }

}
