import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ServerResponse} from "../models/server-response.model";
import {Api} from "../utils/constant";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient) { }

  getAnaliticSearch(days=1): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.analyticSearch+"?days="+days);
  }
}
