import {Component, OnInit} from '@angular/core';
import {Route} from '../../utils/constant';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  {path: Route.stories, title: 'Story List', icon: 'pe-7s-note2', class: ''},
  {path: Route.users, title: 'Users', icon: 'pe-7s-users', class: ''},
  {path: Route.home, title: 'Video Tutorials', icon: 'pe-7s-video', class: ''},
  {path: Route.typography, title: 'Terms and Conditions', icon: 'pe-7s-note', class: ''},
  {path: Route.categories, title: 'Story Categories', icon: 'pe-7s-albums', class: ''},
  {path: Route.analytics, title: 'Analytics', icon: 'pe-7s-graph2', class: ''},
  {path: Route.organizations, title: 'Organization', icon: 'pe-7s-home', class: ''},
  {path: Route.announcement, title: 'Announcement', icon: 'pe-7s-paper-plane', class: ''},
  {path: Route.module, title: 'Module', icon: 'pe-7s-plugin', class: ''},
  {path: Route.userrole, title: 'UserRole', icon: 'pe-7s-id', class: ''}
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  appRouts = Route;
  userSignInEmail: string;

  constructor(private data: DataService, public user: UserService, public router: Router) {
  }

  logout() {
    this.user.logoutUser();
  }

  ngOnInit() {
    let unfilterRoutes = ROUTES.filter(menuItem => menuItem);
    if (localStorage.getItem('role') != "Super Admin"){
      this.data.getUserModules().subscribe(data => {
        if (data["error"] === 0 && data["authError"] === 0) {
          if (!data["data"] || data["data"]["userRoleModules"].length == 0) {
            localStorage.clear();
            this.router.navigate(['/' + Route.login]);
          }
          else{
            let userRoleModules = data["data"]["userRoleModules"];
            unfilterRoutes = unfilterRoutes.filter(menuItem =>
              userRoleModules.some(module => module["moduleName"] === menuItem["title"])
            );
            if(unfilterRoutes.length > 0){
              this.menuItems = unfilterRoutes;
              this.userSignInEmail = localStorage.getItem('userSignInEmail');
              let updatedDefRoute = unfilterRoutes[0].path;
              this.router.navigate(['/' + updatedDefRoute]);
            } else {
              localStorage.clear();
              this.router.navigate(['/' + Route.login]);
            }
          }
  
        } else if (data["error"] === 1 && data["authError"] === 1) {
            localStorage.clear();
            this.router.navigate(['/' + Route.login]);
        }
      });
    }
    else{
      this.menuItems = unfilterRoutes;
      this.userSignInEmail = localStorage.getItem('userSignInEmail');
    }
  }

isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
}
}
