import { Component, OnInit } from '@angular/core';
import {Route} from '../../../utils/constant';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TitleCasePipe} from '@angular/common';
import {FormService} from '../../../services/form.service';
import {UserService} from '../../../services/user.service';
import {CustomValidators} from '../../../validators/equal-validator';
import {ServerResponse} from '../../../models/server-response.model';
import {isObject} from 'util';
import swal from 'sweetalert';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  appRouts = Route;
  formFields = {
    password: '',
    confirm_password: ''
  };

  token: string;
  isInvalidToken: boolean;
  _formErrors: any = {};
  form: FormGroup;
  submitSuccess = false;
  constructor(private router: Router , private route: ActivatedRoute,
              private titlecase: TitleCasePipe,
              private formService: FormService,
              private userService: UserService) { }

  ngOnInit() {
    this._resetFormErrors();
    this.form = new FormGroup({
      password: new FormControl(this.formFields.password, [Validators.required, Validators.minLength(8)]),
      confirm_password: new FormControl(this.formFields.confirm_password, [Validators.required , Validators.minLength(8)])
    }, {
      validators: CustomValidators.Equal('password', 'confirm_password')
    });
  }

  formReset() {
    this.router.navigate(['/' + Route.default]);
  }

  private _resetFormErrors(): void {
    for (const Key in this.formFields) {
      if (this.formFields.hasOwnProperty(Key)) {
        this._formErrors[Key] = {valid: true, message: ''};
      }
    }
  }

  onSubmit() {
    console.log('in submit funtion');
    if (!this.form.invalid) {
      const requestData = this.form.value;
      this.userService.changePassword(requestData).subscribe((response: ServerResponse) => {
          console.log('changePassword');
          if (response.error) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              this.form.get('confirm_password').setErrors({server: true, msg: response.msg});
            }
          } else {
            this.submitSuccess = true;
            swal({
              title: 'Message',
              text: 'Password has been updated successfully',
              icon: 'success'
            });
            this.router.navigate(['/' + Route.default]).catch(reason => {
              console.log('reason', reason);
            });
            console.log('in submit');
          }
        },
        error => {
          console.log(error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      console.log('invalid data');
      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }  else if (this.form.get(field).errors.Equal) {
        msg = 'Password Mismatch.';
      } else if (this.form.get(field).errors.minlength) {
        msg = 'Password should be at least 8 characters long.';
      } else if (this.form.get(field).errors.server) {
        msg = this.form.get(field).errors.msg;
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

}
