import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form.service';
import { announcement } from 'src/app/models/announcement';
import swal from 'sweetalert';
import { ServerResponse } from 'src/app/models/server-response.model';
import {isObject} from "util";
declare var $: any;

@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.css'] 
})
export class AddAnnouncementComponent implements OnInit {

  _formErrors: any = {};
  form: FormGroup;
  anncModel:announcement; 
  announcement$: Object;
  // width: number;
  // height: number;
  responsemessage='';

  @ViewChild('title') titleField: ElementRef;
  @ViewChild('description') descriptionField: ElementRef;
  @ViewChild('ntfnType') ntfnTypeField: ElementRef;
  @ViewChild('ntfnTime') ntfnTimeField: ElementRef;

  constructor(private titlecase: TitleCasePipe, 
              private data: DataService, 
              private route: ActivatedRoute, 
              private formService: FormService, 
              private router: Router) 
              {
                  this.route.params.subscribe(params => this.announcement$ = params.id);
              }


  ngOnInit() { 
    this._resetFormErrors();
    this.form = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
	  ntfnType: new FormControl(null, [Validators.required]),
	  ntfnTime: new FormControl(null),
    });
    this.anncModel = new announcement();
  }
  
    ngAfterViewInit(){
	  
	  
	  $(document.body).on('change','#notify',function(){
		  var opt=$(this).val();
		  var currentdate = new Date(); 
		   var hours = currentdate.getHours();
			var minutes = currentdate.getMinutes();
			var seconds = currentdate.getSeconds();
			var rhours=hours%12;
			var datetime =(currentdate.getMonth()+1)  + "/"  
					+ currentdate.getDate() + "/"
					+ currentdate.getFullYear() + ", " 
					
					+ (rhours ? rhours : '12') + ":" 
					+ (minutes < 10 ? '0'+minutes : minutes) + " " 
					
					+ (hours >= 12 ? 'PM' : "AM");
					
		if(opt=="Schedule"){
			$('#notifytime').val(datetime +" LOCAL");
			$('#notifyTimeDiv').css("display","block");
		}
		else{
			$('#notifyTimeDiv').css("display","none");
		}
	  });
	  
  }

  private _resetFormErrors(): void {
    for (const credentialKey in this.anncModel) {
      if (this.anncModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }


  
  formReset() {
    this.router.navigate(['/' + Route.announcement]);
  }


  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      } if (this.form.get(field).errors.email) {
        msg = 'Email is invalid.';
      }
      if (this.form.get(field).errors.minlength) {
        msg = 'Phone number should not be less than 10 character';
      }
       if (this.form.get(field).errors.pattern) {
        //  if(field=='name')
        //  {
        //   msg =' Name is not valid use only alphabet' ;
        //  }
         if(field=='phone')
         {
          msg ='Phone is not valid use only number' ;
         }
		
      }
	  
      else if (this.form.get(field).errors.server) {
        msg = this.form.get(field).errors.msg;
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

  OnSubmit() {
	if(this.anncModel.ntfnType==undefined){
		swal({
              title: 'Message',
              text: "Please select type",
              closeOnClickOutside : false,
              icon: 'warning'
            });
	}

	if(this.anncModel.ntfnTime==undefined){
		this.anncModel.ntfnTime=new Date();
	}
	  
    if (!this.form.invalid) {
		
	  this.anncModel.noOfSent=0;
      this.anncModel.editMode=0;
      this.anncModel.isExpire=0;
	  this.anncModel.isSent=0; //this.anncModel.ntfnType=="Schedule" ? 0 : 1;
      // this.anncModel.title= this.anncModel.title.toLowerCase();
      this.data.postAnnouncement(this.anncModel).subscribe((response: ServerResponse) => {
          console.log('response', response);
          this.responsemessage = response.msg;
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Announcement Added',
              closeOnClickOutside : false,
              icon: 'success'
            });
            this.router.navigate(['/' + Route.announcement]).catch(reason => {
              console.log('reason', reason);
              //this.initializeImage();
              this.formReset();
            });
          } else if (response.error > 0) {
			  swal({
              title: 'Message',
              text: response.msg,
              closeOnClickOutside : false,
              icon: 'warning'
            });
            if (isObject(response.msg)) {
              
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              // this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
            }
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      if (this.hasError('title')) {
        this.titleField.nativeElement.focus();
      } else {
        console.log('error meassge ');
      }
      return false;
    }
  }

}
