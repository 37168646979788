import {Component, OnInit, ViewChild} from '@angular/core';
import {AnalyticsService} from "../../services/analytics.service";
import {MatSort, MatTableDataSource, MatPaginator} from "@angular/material";
import {Router } from '@angular/router';
import {Api, Route, SERVER_IMAGE_PATH, Token} from '../../utils/constant';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-analytics-keyword-search',
  templateUrl: './analytics-keyword-search.component.html',
  styleUrls: ['./analytics-keyword-search.component.css']
})
export class AnalyticsKeywordSearchComponent implements OnInit {
  searchKeyword = "";
  filter = {days: 30}; 
  daySelector = [];
  analyticsKeywordSearchDataSource;
  displayColumns = ['id', 'text', 'count'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private analyticService: AnalyticsService,public user: UserService,private router: Router) {
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.initialiseDaySelector();
    this.getData();
  }
}

  initialiseDaySelector() {
    for (let i = 1; i <= 30; i++) {
      this.daySelector.push({value: i, text: "Last " + i + (i !== 1 ? " days" : " day")});
    }
  }

  getData() {
    this.analyticService.getAnaliticSearch(this.filter.days).subscribe((response) => {
      if (!response.error) {
        this.analyticsKeywordSearchDataSource = new MatTableDataSource(response.data.searchHistory);
		this.analyticsKeywordSearchDataSource.paginator = this.paginator;
        this.analyticsKeywordSearchDataSource.sort = this.sort;
      }
    });
  }

  searchAnalytics() {
    this.analyticsKeywordSearchDataSource.filter = this.searchKeyword;
  }

  resetSearch() {
    this.analyticsKeywordSearchDataSource.filter = this.searchKeyword = ""
  }
}
