import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TitleCasePipe} from '@angular/common';
import {DataService} from '../../services/data.service';
import {FormService} from '../../services/form.service';
import swal from 'sweetalert';
import {ServerResponse} from '../../models/server-response.model';
import {isObject} from "util";
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {ActivatedRoute, Router} from '@angular/router';
import {Module} from '../../models/module';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.css']
})
export class AddModuleComponent implements OnInit {

  _formErrors: any = {};
  form: FormGroup;
  moduleModel: Module;
  module$: Object;


  @ViewChild('title') titleField: ElementRef;
  @ViewChild('description') descriptionField: ElementRef;
  @ViewChild('active') activeField: ElementRef;


  constructor(private titlecase: TitleCasePipe, private data: DataService, private user: UserService, private route: ActivatedRoute, private formService: FormService, private router: Router) {
    this.route.params.subscribe(params => this.module$ = params.id);
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
      this._resetFormErrors();
      this.form = new FormGroup({
        title: new FormControl(null, [Validators.required]),
        active: new FormControl(null, []),
        description: new FormControl(null, [])
      });
      this.moduleModel = new Module();
    }
  }

  private _resetFormErrors(): void {
    for (const credentialKey in this.moduleModel) {
      if (this.moduleModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }

  formReset() {
    this.router.navigate(['/' + Route.module]);
  }

  OnSubmit() {
    if (!this.form.invalid) {
      this.data.postModule(this.moduleModel).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Module is Added Successfully',
              icon: 'success'
            });
            this.formReset();
            this.router.navigate(['/' + Route.module]).catch(reason => {
              console.log('reason', reason);
            });
          } else if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              // this.form.get('title').setErrors({server: true, msg: response.msg});
            }
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      if (this.hasError('title')) {
        this.titleField.nativeElement.focus();
      }  
      else {
        console.log('error meassge '); 
      }

      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''}; 
    }
    return !this._formErrors[field].valid;
  }

}
