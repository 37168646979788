import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TitleCasePipe} from '@angular/common';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../services/form.service';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {Api, Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {isObject} from 'util';
import {Module} from '../../models/module';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-module',
  templateUrl: './edit-module.component.html',
  styleUrls: ['./edit-module.component.css']
})
export class EditModuleComponent implements OnInit {
  _formErrors: any = {};
  form: FormGroup;
  moduleModel = new Module();

  @ViewChild('title') titleField: ElementRef;
  @ViewChild('description') descriptionField: ElementRef;
  @ViewChild('active') activeField: ElementRef;

  constructor(private titlecase: TitleCasePipe,private users: UserService, private data: DataService, private route: ActivatedRoute,
              private formService: FormService, private router: Router) {
    this.route.params.subscribe(params => this.moduleModel.id = params.id);
  }

  ngOnInit() {
    // @ts-ignore
    if(this.users.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getModule(this.moduleModel.id).subscribe((data: ServerResponse) => {
      this.moduleModel = data.data;
    });
    this._resetFormErrors();
    this.form = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      active: new FormControl(null, []),
      description: new FormControl(null, []),
    });
    // this.initializeImage();
  }
  }
  private _resetFormErrors(): void {
    for (const credentialKey in this.moduleModel) {
      if (this.moduleModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }

  formReset() {
    this.router.navigate(['/' + Route.module]);
  }

  updateModule() {
    if (!this.form.invalid) {
      this.data.updateModule(this.moduleModel).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Module is Updated Successfully',
              closeOnClickOutside : false,
              icon: 'success'
            });
            this.router.navigate(['/' + Route.categories]).catch(reason => {
              console.log('reason', reason);
              this.formReset();
            });
          } else if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              // this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
            }
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      if (this.hasError('title')) {
        this.titleField.nativeElement.focus();
      } else {
        console.log('error meassge ');
      }
      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

}
