import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../../services/form.service';
import {TitleCasePipe} from '@angular/common';
import {UserService} from '../../../services/user.service';
import {ServerResponse} from '../../../models/server-response.model';
import {isObject} from 'util';
import {Route} from '../../../utils/constant';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  appRouts = Route;
  formFields = {
    email: ''
  };

  _formErrors: any = {};
  form: FormGroup;
  submitSuccess = false;

  constructor(private titlecase: TitleCasePipe,
              private formService: FormService,
              private userService: UserService) {
  }

  ngOnInit() {
    this._resetFormErrors();
    this.form = new FormGroup({
      email: new FormControl(this.formFields.email, [Validators.required])
    });
  }

  private _resetFormErrors(): void {
    for (const Key in this.formFields) {
      if (this.formFields.hasOwnProperty(Key)) {
        this._formErrors[Key] = {valid: true, message: ''};
      }
    }
  }

  onSubmit() {
    console.log(this.form.value);
    if (!this.form.invalid) {
      this.userService.forgot(this.form.value).subscribe((response: ServerResponse) => {
          if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              this.form.get('email').setErrors({server: true, msg: response.msg});
            }
          } else {
            this.submitSuccess = true;
          }
        },
        error => {
          console.log(error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      } else if (this.form.get(field).errors.email) {
        msg = 'Email is invalid.';
      } else if (this.form.get(field).errors.Equal) {
        msg = 'Password Mismatch.';
      } else if (this.form.get(field).errors.server) {
        msg = this.form.get(field).errors.msg;
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

}
