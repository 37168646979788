import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../../services/form.service';
import {isObject} from 'util';
import {TitleCasePipe} from '@angular/common';
import {ServerResponse} from '../../../models/server-response.model';
import {UserService} from '../../../services/user.service';
import {CustomValidators} from '../../../validators/equal-validator';
import {ActivatedRoute} from '@angular/router';
import {Utils} from '../../../utils/utility';
import {Route} from '../../../utils/constant';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  appRouts = Route;
  formFields = {
    email: '',
    password: '',
    confirm_password: ''
  };

  token: string;
  isInvalidToken: boolean;
  _formErrors: any = {};
  form: FormGroup;
  submitSuccess = false;

  constructor(private route: ActivatedRoute,
              private titlecase: TitleCasePipe,
              private formService: FormService,
              private userService: UserService) {
  }

  ngOnInit() {
    this._verrifyToken();
    this._resetFormErrors();
    this.form = new FormGroup({
      email: new FormControl(this.formFields.email, [Validators.required ]),
      password: new FormControl(this.formFields.password, [Validators.required, Validators.minLength(8)]),
      confirm_password: new FormControl(this.formFields.confirm_password, [Validators.required ])
    }, {
      validators: CustomValidators.Equal('password', 'confirm_password')
    });
  }

  private _getToken() {
    if (this.route.snapshot.queryParams.token) {
      this.token = this.route.snapshot.queryParams.token;
    } else {
      this.isInvalidToken = true;
    }
  }

  private _verrifyToken() {
    this._getToken();
    this.userService.verifyToken(this.token).subscribe((response: ServerResponse) => {
      Utils.analyseResponse(response);
      if (response.error) {
        this.isInvalidToken = true;
      } else {
        this.isInvalidToken = false;
        this.formFields.email = response.data.email;
      }
    }, error => {
      console.log(error);
    });
  }

  private _resetFormErrors(): void {
    for (const Key in this.formFields) {
      if (this.formFields.hasOwnProperty(Key)) {
        this._formErrors[Key] = {valid: true, message: ''};
      }
    }
  }

  onSubmit() {
    if (!this.form.invalid) {
      console.log('form', this.form.value);
      const requestData = this.form.value;
      requestData.token = this.token;
      this.userService.resetPassword(requestData).subscribe((response: ServerResponse) => {
          if (response.error) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  // this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              this.form.get('confirm_password').setErrors({server: true, msg: response.msg});
            }
          } else {
            this.submitSuccess = true;
          }
        },
        error => {
          console.log(error.message);
        });
    } else {
      console.log('form else', this.form);
      this.formService.validateAllFormFields(this.form);
      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      } else if (this.form.get(field).errors.email) {
        msg = 'Email is invalid.';
      } else if (this.form.get(field).errors.Equal) {
        if (this.form.get('password').value !== this.form.get('confirm_password').value) {
          msg = 'Password Mismatch.';
        }
      } else if (this.form.get(field).errors.minlength) {
        msg = 'Password should be at least 8 characters long.';
      } else if (this.form.get(field).errors.server) {
        msg = this.form.get(field).errors.msg;
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

}
