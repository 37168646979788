import { Component, OnInit } from '@angular/core';
import { school } from 'src/app/models/school';
import { DataService } from 'src/app/services/data.service';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form.service';
import { ServerResponse } from 'src/app/models/server-response.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Route, Token} from '../../utils/constant';
import { UserService } from 'src/app/services/user.service';
import {isObject} from 'util';
import { ViewChild, ElementRef} from '@angular/core';
import swal from 'sweetalert';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { recipt } from 'src/app/models/recipt';
import { Signup } from 'src/app/models/signup';
import { forEach } from '@angular/router/src/utils/collection';
declare var $: any;
declare global {
			interface Window {
				amplitude: any;
			}
		}
@Component({
  selector: 'app-landing-page',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.css']
})


export class NewsLetterComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  loader = false;
  credential = {
    email: '',
    password: '',
    appVersion : 'V2'
  };
  form: FormGroup;
  organizatoDescription;
  form1: FormGroup;
  _formErrors: any = {};
  _formErrors1: any = {};
  
  amplitude:any;
  
  appRouts = Route;
  reciptModel = recipt;
  recipt$ =new recipt();
  school$ = new school();
  signupModel:Signup;
  signup$: Object;
  @ViewChild('closeSigninModal') closeSigninModal: ElementRef;
  @ViewChild('closeSignupModal') closeSignupModal: ElementRef;
  @ViewChild('name') nameField: ElementRef;
  @ViewChild('email') emailField: ElementRef;
  @ViewChild('phone') phoneField: ElementRef;
  @ViewChild('password') passwordField: ElementRef;
  showSuccess: boolean;
  
  constructor(private data: DataService,
              private titlecase: TitleCasePipe,
              private formService: FormService,
              private user: UserService,
              private router: Router,
              private route: ActivatedRoute) {
                //this.route.params.subscribe(params => this.school$.title = params.title);
  }


	
	
  ngOnInit() {
	  
  }
  
  ngAfterViewInit() {
	  
  }

}
