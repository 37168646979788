import { Component, OnInit } from '@angular/core';
import { school } from 'src/app/models/school';
import { DataService } from 'src/app/services/data.service';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form.service';
import { ServerResponse } from 'src/app/models/server-response.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Route, Token} from '../../utils/constant';
import { UserService } from 'src/app/services/user.service';
import {isObject} from 'util';
import { ViewChild, ElementRef} from '@angular/core';
import swal from 'sweetalert';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { recipt } from 'src/app/models/recipt';
import { Signup } from 'src/app/models/signup';
import { forEach } from '@angular/router/src/utils/collection';
declare var $: any;
declare global {
			interface Window {
				amplitude: any;
			}
		}
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  loader = false;
  credential = {
    email: '',
    password: '',
    appVersion : 'V2'
  };
  form: FormGroup;
  organizatoDescription;
  form1: FormGroup;
  _formErrors: any = {};
  _formErrors1: any = {};
  appRouts = Route;
  reciptModel = recipt;
  recipt$ =new recipt();
  school$ = new school();
  signupModel:Signup;
  signup$: Object;
  @ViewChild('closeSigninModal') closeSigninModal: ElementRef;
  @ViewChild('closeSignupModal') closeSignupModal: ElementRef;
  @ViewChild('name') nameField: ElementRef;
  @ViewChild('email') emailField: ElementRef;
  @ViewChild('phone') phoneField: ElementRef;
  @ViewChild('password') passwordField: ElementRef;
  showSuccess: boolean;
  constructor(private data: DataService,
              private titlecase: TitleCasePipe,
              private formService: FormService,
              private user: UserService,
              private router: Router,
              private route: ActivatedRoute) {
                this.route.params.subscribe(params => this.school$.title = params.title);
  }

  ngOnInit() {
    
	(function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
	  ;r.type="text/javascript"
	  ;r.integrity="sha384-RsEu4WZflrqYcEacpfoGSib3qaSvdYwT4D+DrWqeBuDarSzjwUQR1jO8gDiXZd0E"
	  ;r.crossOrigin="anonymous";r.async=true
	  ;r.src="https://cdn.amplitude.com/libs/amplitude-6.2.0-min.gz.js"
	  ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
	  console.log("[Amplitude] Error: could not load SDK")}}
	  ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
	  ;function s(e,t){e.prototype[t]=function(){
	  this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
	  var o=function(){this._q=[];return this}
	  ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
	  ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
	  ;return this}
	  ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
	  ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
	  ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId", "enableTracking", "setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
	  ;function v(e){function t(t){e[t]=function(){
	  e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
	  for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
	  e=(!e||e.length===0?"$default_instance":e).toLowerCase()
	  ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
	  ;e.amplitude=n})(window,document);
	  
	   window.amplitude.getInstance().init("b2e6fcad8bee39d92b13d32143ccc743");
	  //amplitude.logEvent('Clicked Homepage Button', {'finished_flow': false, 'clicks': 15});
	  
	  window.amplitude.getInstance().setUserId("ORG_PAGE="+this.school$.title);
	  window.amplitude.getInstance().logEvent("ORGANIZATION_LOAD",{'organization_name': this.school$.name});
	  
	  
	this.initConfig();
    this.data.getLandinPageDetail(this.school$.title).subscribe((data: ServerResponse) => {
      console.log("organization id ",data.data.id);
      localStorage.setItem('organizationId' , data.data.id);
      this.school$ = data.data;
      this.school$.description = this. school$.description;
      this.school$.image =this. school$.image;
	  
	   
	  // jQuery code for slider 
	   if(this.school$.language!="English"){
			var timeDelay=2000;
			  setTimeout(function(){
			   var jcarousel = $('.jcarousel').jcarousel();
			   
			   try{
				   console.log(jcarousel.length);
				   console.log("inc");
				 jcarousel.on('jcarousel:reload jcarousel:create', function () {
						console.log("inside");
						var carousel = $(this),
							width = carousel.innerWidth();

						if (width >= 1000) {
							width = width / 5;
						} else if (width >= 550) {
							width = width / 5;
						}else if (width >= 320) {
							width = width / 2;
							console.log("case");
						}
						else{width = width / 2;}
						 
						carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
					}).jcarousel({wrap: 'circular'}).jcarouselAutoscroll({interval: 1500});
				console.log("i2c");
				$('.jcarousel-control-prev').jcarouselControl({target: '-=1'});
				$('.jcarousel-control-next').jcarouselControl({target: '+=1'});
			   }catch(err){console.log(err.message);}
				},timeDelay);
	   }
	  
    });

    this._resetFormErrors1();
    this.form1 = new FormGroup({
      email: new FormControl(this.credential.email, [Validators.required]),
      password: new FormControl(this.credential.password, [Validators.required]),
      appVersion: new FormControl(this.credential.appVersion),
    });
    this._resetFormErrors();
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required,Validators.pattern(/^[a-zA-Z\s]+$/)]),
      email: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.pattern('[0-9]+')]),
      password: new FormControl(null, [Validators.required]),
    });
    this.signupModel = new Signup();
  }

	ngAfterViewInit() {
	    

	 //slide_loader();
	   var jcarousel = $('.jcarousel').jcarousel();
	   
	   try{
		   
        jcarousel.on('jcarousel:reload jcarousel:create', function () {
				console.log("slider");
                var carousel = $(this),
                    width = carousel.innerWidth();

                if (width >= 1000) {
                    width = width / 5;
                } else if (width >= 550) {
                    width = width / 5;
                }else if (width >= 320) {
                    width = width / 2;
					console.log("case");
                }
				else{width = width / 2;}
				 
                carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
            }).jcarousel({wrap: 'circular'}).jcarouselAutoscroll({interval: 1500});
		
        $('.jcarousel-control-prev').jcarouselControl({target: '-=1'});
        $('.jcarousel-control-next').jcarouselControl({target: '+=1'});
	   }catch(err){console.log(err.message);}
	   
	   var ourl      = window.location.href;
	   ourl=ourl.split('/').pop();
	   
	  
	  $('#buy_now').click(function(e){
		  e.preventDefault();
		  var email_inc=localStorage.getItem('webSignInEmail')? localStorage.getItem('webSignInEmail'):"Anonymous" ;
		  window.amplitude.getInstance().setUserId(email_inc+"**"+ourl);
		  window.amplitude.getInstance().logEvent("BUY_NOW",{'message': 'Buy Now'});
			
		  
	  });
	  
	  $('.app_store').click(function(e){
		  //e.preventDefault();
		  var email_inc=localStorage.getItem('webSignInEmail')? localStorage.getItem('webSignInEmail'):"Anonymous" ;
		  window.amplitude.getInstance().setUserId(email_inc+"**"+ourl);
		  window.amplitude.getInstance().logEvent("APP_STORE",{'message': 'App store'});
			
		  
	  });
	  
	  $('.play_store').click(function(e){
		  //e.preventDefault();
		  var email_inc=localStorage.getItem('webSignInEmail')? localStorage.getItem('webSignInEmail'):"Anonymous" ;
		  window.amplitude.getInstance().setUserId(email_inc+"**"+ourl);
		  window.amplitude.getInstance().logEvent("PLAY_STORE",{'message': 'Play store'});
			
		  
	  });
	  
  }
  
   getWebUserState(){
	  return this.user.isweblogin();
  }
  
  getUserState(){
	  return this.user.islogin();
  }

  private _resetFormErrors1(): void {
    for (const credentialKey in this.school$) {
      if(this.school$.hasOwnProperty(credentialKey)) {
        this._formErrors1[credentialKey] = {valid: true, message: ''};
      }
    }
  }
  private _resetFormErrors(): void {
    for (const credentialKey in this.signupModel) {
      if (this.signupModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }

  formReset() {
    this.router.navigate(['/' + Route.landingpage]);
  }

  onSubmitLogin() {
    console.log("login Form");
    this.loader = false;
    if (!this.form1.invalid) {
      this.loader = true;
      this.user.landingpagelogin(this.form1.value).subscribe((response: ServerResponse) => {
          if (response.error === 0 && response.authError === 0) {
            localStorage.setItem('webSignInEmail', response.data.email);
            localStorage.setItem(Token.webuserToken, response.data.token);
            this.loader = false;
            this.closeSigninModal.nativeElement.click();
            swal({
              title: 'Success!',
              text: 'Click OK to provide payment.',
              icon: 'success'
            });
			window.amplitude.getInstance().setUserId(response.data.email+"**"+this.school$.title);
			window.amplitude.getInstance().logEvent("LOGIN_SUCCESS",{'email': response.data.email,'org':this.school$.title});
          } else if (response.authError === 1) {
            this.loader = false;
            localStorage.clear();
            this.router.navigate(['/' + Route.landingpage]);
          } else if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form1.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                  console.log('error in auth');
                }
              }
            } else {
              this.form1.get('password').setErrors({server: true, msg: response.msg});
              this.loader = false;
            }
          }
        },
        error => {
          console.log('error.message', error.message);
        });
    } else {
      this.loader = false;
      this.formService.validateAllFormFields(this.form1);
      return false;
    }
  }

  hasError1(field): boolean {
    if (this.form1.get(field).invalid && this.form1.get(field).touched) {
      let msg = '';
      if (this.form1.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      } else if (this.form1.get(field).errors.email) {
        msg = 'Email is invalid.';
      } else if (this.form1.get(field).errors.Equal) {
        msg = 'Password Mismatch.';
      } else if (this.form1.get(field).errors.server) {
        msg = this.form1.get(field).errors.msg;
      }
      this._formErrors1[field] = {valid: false, message: msg};
    }
    else {
      this._formErrors1[field] = {valid: true, message: ''};
    }
    return !this._formErrors1[field].valid;
  }

  OnSubmitSignup() {
    this.loader = true;
    if (!this.form.invalid) {

      this.data.postsignup(this.signupModel).subscribe((response: ServerResponse) => {
          console.log('signUpresponse', response);
          if (response.error === 0 && response.authError === 0) {
            localStorage.setItem('webSignInEmail', response.data.email);
            localStorage.setItem(Token.webuserToken, response.data.token);
            this.loader = false;
            this.closeSignupModal.nativeElement.click();
            swal({
              title: 'Success!',
              text: 'Click OK to provide payment.',
              closeOnClickOutside : false,
              icon: 'success'
            });
			window.amplitude.getInstance().setUserId(response.data.email+"**"+this.school$.title);
			window.amplitude.getInstance().logEvent("SIGNUP_SUCCESS",{'email': response.data.email,'org':this.school$.title});
          } else if (response.error > 0) {
            this.loader = false;
            this.form.get('email').setErrors({server: true, msg: response.msg});
            if (isObject(response.msg)) {
              this.form.get('email').setErrors({server: true, msg: response.msg});
              for (const msgKey in response.msg) {

                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              this.loader = false;
            }
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      this.loader = false;
      if (this.hasError('name')) {
        this.nameField.nativeElement.focus();
      }
      else if (this.hasError('email')) {
        this.emailField.nativeElement.focus();
      }
      else if (this.hasError('phone')) {
        this.phoneField.nativeElement.focus();
      }
      else if (this.hasError('password')) {
        this.passwordField.nativeElement.focus();
      }
      else {
        console.log('error meassge ');
      }
      return false;
    }
  }
  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      } if (this.form.get(field).errors.email) {
        msg = 'Email is invalid.';
      }
       if (this.form.get(field).errors.pattern) {
         if(field=='name')
         {
          msg =' Name is not valid use only alphabet' ;
         }
         else if(field=='phone')
         {
          msg ='Phone is not valid use only number' ;
         }

      }
      else if (this.form.get(field).errors.server) {
        msg = this.form.get(field).errors.msg;
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }
  removeEmail() {
	  
	window.amplitude.getInstance().setUserId(localStorage.getItem('webSignInEmail')+"**"+this.school$.title);
	window.amplitude.getInstance().logEvent("LOGOUT_SUCCESS",{'message': 'logout'});
    this.user.logoutWebUser();
  }

  private initConfig(): void {
    this.payPalConfig = {
    currency: 'USD',
    clientId: 'AXHBvxswTyLPxum1EB_vGoQldyfzt308ShBHko202_VQiC-tWOl0xz0T8thlTjJJQrO0K2NzFWW8W6Af', //Sandbox
     //clientId: 'ATDORecH12JTHS5sErqRQ61pdpzYVAE58-uXzAUmUm4Oq0tyIjjkhLFQ6LsBvZjUaHCyp3Lhzjb9qnUP',  //Live


      createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: '50',
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: '50'
              }
            }
          },
          items: [
            {
              name: 'Stories',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'USD',
                value: '50',
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
    },
    onApprove: (data, actions) => {
      console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        console.log('onApprove - you can get full order details inside onApprove: ', details);
      });
    },

    onClientAuthorization: (data) => {

    console.log('onClientAuthorization', data);

     this.recipt$.data= data;
     this.recipt$.organization_id = localStorage.getItem("organizationId");

      this.data.postrecipt(this.recipt$).subscribe((response: ServerResponse) => {
        console.log('paypalresponse', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Success!',
            text: 'Yay! Your transaction was successful. You have made a payment with this email: ' + response.data.email,
            icon: 'success',
          });
		  
			window.amplitude.getInstance().setUserId(response.data.email+"**"+this.school$.title);
		  window.amplitude.getInstance().logEvent("PAYMENT_SUCCESS",{'email': response.data.email,'org':this.school$.title});

        } else if (response.error > 0) {
          swal({
            title: 'Alert',
            text: ''+response.msg,
            icon: 'info',
          });
		  window.amplitude.getInstance().setUserId(localStorage.getItem('webSignInEmail')+"**"+this.school$.title);
		  window.amplitude.getInstance().logEvent("PAYMENT_FAIL/ALREADY_BY_SERVER",{'email':localStorage.getItem('webSignInEmail'),'message': response.msg,'org':this.school$.title});
		  
        }
      },
      error => {
		  window.amplitude.getInstance().setUserId(localStorage.getItem('webSignInEmail')+"**"+this.school$.title);
		  window.amplitude.getInstance().logEvent("PAYMENT_FAIL_BY_PAYPAL",{'email':localStorage.getItem('webSignInEmail'),'message': error.message,'org':this.school$.title});
		  
        console.log('error meassge ', error.message);
      });
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);
    },
  };
  }

}
