import {Injectable} from '@angular/core';
import {TitleCasePipe} from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';

@Injectable()
export class FormService {

  constructor(private titlecase: TitleCasePipe) {
  }

  validateAllFormFields(formGroup: FormGroup) {         // {1}
    Object.keys(formGroup.controls).forEach(field => {  // {2}
      const control = formGroup.get(field);             // {3}
      if (control instanceof FormControl) {             // {4}
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        // {5}
        this.validateAllFormFields(control);            // {6}
      }
    });
  }

}
