import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Page} from '../../models/page';
import {Story} from '../../models/story';
import swal from 'sweetalert';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../services/form.service';
import {ServerResponse} from '../../models/server-response.model';
import {Api, Route, SERVER_IMAGE_PATH, Token} from '../../utils/constant';
import {isObject} from 'util';
import {TitleCasePipe} from '@angular/common';
import {MatTableDataSource} from '@angular/material';
import { UserService } from 'src/app/services/user.service';

declare var $: any;

@Component({
  selector: 'app-addstory',
  templateUrl: './addstory.component.html',
  styleUrls: ['./addstory.component.scss']
})
export class AddstoryComponent implements OnInit {
  _formErrors: any = {};
  form: FormGroup;
  pageModel: Page;
  storyModel: Story;
  story$: Object;

  fileToUpload: File = null;
  imageUrl = 'assets/img/default-image.png';
  width: number;
  height: number;
  counter = 0;
  categoryList: Array<any> ;
  typeList: Array<any> = [
    {id: 1, title: 'Free'},
    {id: 2, title: 'Featured'},
    {id: 3, title: 'Premium'},
  ] ;

  @ViewChild('category') categoryField: ElementRef;
  @ViewChild('title') titleField: ElementRef;
  // @ViewChild('price') priceField: ElementRef;
  @ViewChild('pace') paceField: ElementRef;
  // @ViewChild('productId')productIdField: ElementRef;
  @ViewChild('thumbnailFocus') thumbnailFocusField: ElementRef;


  constructor(private titlecase: TitleCasePipe, private data: DataService,public user: UserService, private route: ActivatedRoute, private formService: FormService, private router: Router) {
    this.route.params.subscribe(params => this.story$ = params.id);
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this._resetFormErrors();
    this.form = new FormGroup({
      category: new FormControl(''),
      title: new FormControl(null, [Validators.required]),
      // price: new FormControl(null, [Validators.required]),
      pace: new FormControl(null, [Validators.required]),
      // productId : new FormControl (null, [Validators.required]),
      isActive: new FormControl(null, []),
      thumbnail: new FormControl(null, [Validators.required]),
      tags: new FormControl(''),
      subscriptionType: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required])
    });
    this.initializeImage();
    this.getCategories();
  }
  }
   getCategories(){
     this.data.getCategories().subscribe(
       data => {
         if (!data) {
           return;
         }
         this.categoryList = data.data.categories;
       });
   }
  private _resetFormErrors(): void {
    for (const credentialKey in this.storyModel) {
      if (this.storyModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }


  addPage() {
    this.storyModel.page.push(new Page());
  }

  onPageDeleted(index) {
    console.log('counter in delete page', this.counter);
    if (index !== -1) {
      this.storyModel.page.splice(index, 1);
      if (this.counter > 0) {
        this.counter = this.counter - 1;
      }
    }
  }

  displayCounter(pageObj) {
    console.log('pageObj', pageObj);
    console.log('array', this.storyModel.page);
    console.log('counter', this.counter);
    let temp: number;
    if (pageObj.index == null) {
      temp = this.counter;
      this.counter = this.counter + 1;
    } else {
      temp = pageObj.index;
    }
    this.storyModel.page[temp].index = temp;
    this.storyModel.page[temp].content = pageObj.content;
    this.storyModel.page[temp].image = pageObj.image;
    this.storyModel.page[temp].type = pageObj.type;
    swal({
      title: 'Message',
      text: 'Page is saved',
      icon: 'success'
    });
  }

  thumbnailhandleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    if (this.fileToUpload.size / 1024 / 1024 > 1) {
      swal({
        title: 'Message',
        text: 'File Size cannot be more than 1MB',
        icon: 'warning'
      });
    } else {
      // when file has loaded
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      swal({
        title: 'Please Wait...',
        text: 'Image is uploading....',
        closeOnClickOutside: false,
        icon: 'warning',
        timer: 1500,
        buttons: [
          false, false
        ]
      });

      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          this.width = img.width;
          this.height = img.height;
          console.log('this.width', this.width);
          console.log('this.height', this.height);
          if (this.width === 180 && this.height === 180) {
            this.data.postFile(this.fileToUpload).subscribe((response: ServerResponse) => {
                console.log('response', response);
                if (response.error === 0 && response.authError === 0) {
                  this.imageUrl = SERVER_IMAGE_PATH + response.data.fileUrl;
                  this.storyModel.thumbnail = response.data.fileUrl;
                  swal({
                    title: 'Message',
                    text: 'Image has been Uploaded Successfully!',
                    closeOnClickOutside: false,
                    icon: 'success'
                  });
                  swal.stopLoading();
                  // swal.close();
                } else if (response.error > 0) {
                  if (isObject(response.msg)) {
                  } else {
                    this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
                  }
                }
              },
              error => {
                console.log('error of file ', error.message);
              });
          } else {
            swal({
              title: 'Message',
              text: 'Image must be of 180 X 180',
              icon: 'warning'
            });
          }
        };
        img.src = event.target.result;
        this.storyModel.thumbnail = img.src;
      };
    }
  }

  removethumbnailhandleFileInput() {
    console.log('In remvoe this.storyModel.thumbnail', this.storyModel.thumbnail);
    this.imageUrl = 'assets/img/default-image.png';
    this.storyModel.thumbnail = null;
    console.log('this.thumbnailFocusField.nativeElement.value', this.thumbnailFocusField.nativeElement.value);
    this.thumbnailFocusField.nativeElement.value = '';
  }

  formReset() {
    this.router.navigate(['/' + Route.default]);
  }

  OnSubmit() {
    event.preventDefault();
    if (this.storyModel.page.length === 0) {
      swal({
        title: 'Message',
        text: 'Please Select at least one page of the story ',
        icon: 'warning'
      });
      return false;
    }
    for (let i = 0; i < this.storyModel.page.length; i++) {
      if (this.storyModel.page[i].type == null || this.storyModel.page[i].type.trim()=="") {
        swal({
          title: 'Message',
          text: 'Please Select the Type of the Page No. ' + (i + 1) + ' \n or Save the Page No.' + (i + 1),
          icon: 'warning'
        });
        return false;
      }
    }
    if (!this.form.invalid) {
      console.log('fileupload', this.fileToUpload);
      this.data.postStory(this.storyModel).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Story is Added Successfully',
              icon: 'success'
            });
            this.initializeImage();
            this.formReset();
            this.router.navigate(['/' + Route.default]).catch(reason => {
              console.log('reason', reason);
            });
          } else if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              // this.form.get('title').setErrors({server: true, msg: response.msg});
            }
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      if (this.hasError('title')) {
        this.titleField.nativeElement.focus();
      }
      // else if (this.hasError('price')) {
      //   this.priceField.nativeElement.focus();
      // }
     /* else if (this.hasError('category')) {
          swal({
            title: 'Message',
            text: 'Please Select at least one Category',
            icon: 'warning'
          });
      }*/
      else if (this.hasError('pace')) {
        this.paceField.nativeElement.focus();
      }
      // else if (this.hasError('productId'))  {
      //   this.productIdField.nativeElement.focus();
      // }
      else if (this.imageUrl !== 'assets/img/default-image.png' && this.storyModel.thumbnail == null) {
        this.thumbnailFocusField.nativeElement.focus();
      } else {
        console.log('error message ');
      }

      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

  initializeImage() {
    this.counter = 0;
    this.storyModel = new Story();
    this.imageUrl = 'assets/img/default-image.png';
    this.pageModel = new Page();
    this.storyModel.page = [new Page()];
  }
  changeStatus(e) {
    if (e.target.checked) {
      this.storyModel.isActive = 1;
    } else {
      this.storyModel.isActive = 0;

    }
  }

  // blockSpecialChar(e) {
  //   console.log('change');
  //   const keyCode = (e.which) ? e.which : e.keyCode;
  //
  //   if ((keyCode >= 48 && keyCode <= 57) || (keyCode === 8)) {
  //     return true;
  //   } else if (keyCode === 46) {
  //     const curVal = document.activeElement.value;
  //
  //     if (curVal != null && curVal.trim().indexOf('.') === -1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  //
  // }
}
