import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Page} from '../../models/page';
import {Story} from '../../models/story';
import {TitleCasePipe} from '@angular/common';
import {DataService} from '../../services/data.service';
import {FormService} from '../../services/form.service';
import swal from 'sweetalert';
import {ServerResponse} from '../../models/server-response.model';
import {isObject} from "util";
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {ActivatedRoute, Router} from '@angular/router';
import {Category} from '../../models/category';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {

  _formErrors: any = {};
  form: FormGroup;
  pageModel: Page;
  storyModel: Story;
  categoryModel: Category;
  category$: Object;

  fileToUpload: File = null;
  imageUrl = 'assets/img/default-image.png';
  width: number;
  height: number;
  counter = 0;
  @ViewChild('title') titleField: ElementRef;
  @ViewChild('description') descriptionField: ElementRef;
  @ViewChild('thumbnailFocus') thumbnailFocusField: ElementRef;


  constructor(private titlecase: TitleCasePipe, private data: DataService, private user: UserService, private route: ActivatedRoute, private formService: FormService, private router: Router) {
    this.route.params.subscribe(params => this.category$ = params.id);
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this._resetFormErrors();
    this.form = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      img: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required, Validators.maxLength(50)])
    });
    this.initializeImage();
  }
  }

  private _resetFormErrors(): void {
    for (const credentialKey in this.categoryModel) {
      if (this.categoryModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }
  thumbnailhandleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    if (this.fileToUpload.size / 1024 / 1024 > 1) {
      swal({
        title: 'Message',
        text: 'File Size cannot be more than 1MB',
        icon: 'warning'
      });
    } else {
      // when file has loaded
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      swal({
        title: 'Please Wait...',
        text: 'Image is uploading....',
        icon: 'warning',
        timer: 1500,
        closeOnClickOutside: false,
        buttons: [
          false, false
        ]
      });

      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          this.width = img.width;
          this.height = img.height;
          console.log('this.width', this.width);
          console.log('this.height', this.height);
          if (this.width === 180 && this.height === 180) {
            this.data.postFile(this.fileToUpload).subscribe((response: ServerResponse) => {
                console.log('response', response);
                if (response.error === 0 && response.authError === 0) {
                  this.imageUrl = SERVER_IMAGE_PATH + response.data.fileUrl;
                  this.categoryModel.img = response.data.fileUrl;
                  swal({
                    title: 'Message',
                    text: 'Image has been Uploaded Successfully!',
                    closeOnClickOutside: false,
                    icon: 'success'
                  });
                  swal.stopLoading();
                  // swal.close();
                } else if (response.error > 0) {
                  if (isObject(response.msg)) {
                  } else {
                    this.form.get('img').setErrors({server: true, msg: response.msg});
                  }
                }
              },
              error => {
                console.log('error of file ', error.message);
              });
          } else {
            swal({
              title: 'Message',
              text: 'Image must be of 180 X 180',
              icon: 'warning'
            });
          }
        };
        img.src = event.target.result;
        this.categoryModel.img = img.src;
      };
    }
  }

  removethumbnailhandleFileInput() {
    console.log('In remvoe this.storyModel.thumbnail', this.categoryModel.img);
    this.imageUrl = 'assets/img/default-image.png';
    this.categoryModel.img = null;
    console.log('this.thumbnailFocusField.nativeElement.value', this.thumbnailFocusField.nativeElement.value);
    this.thumbnailFocusField.nativeElement.value = '';
  }

  formReset() {
    this.router.navigate(['/' + Route.categories]);
  }

  OnSubmit() {
    if (!this.form.invalid) {
      this.data.postCategory(this.categoryModel).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Category is Added Successfully',
              icon: 'success'
            });
            this.initializeImage();
            this.formReset();
            this.router.navigate(['/' + Route.categories]).catch(reason => {
              console.log('reason', reason);
            });
          } else if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              // this.form.get('title').setErrors({server: true, msg: response.msg});
            }
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      if (this.hasError('title')) {
        this.titleField.nativeElement.focus();
      } 
      else if (this.hasError('description')) {
        this. descriptionField.nativeElement.focus();
      } 
      else if (this.imageUrl !== 'assets/img/default-image.png' && this.categoryModel.img == null) {
        this.thumbnailFocusField.nativeElement.focus();
      } 
      else {
        console.log('error meassge '); 
      }

      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        if(field == 'img'){
          msg = 'Image cannot be blank.';
        } else {
          msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
        }
      }
      if (this.form.get(field).errors.maxlength) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' should not be more than '+this.form.get(field).errors.maxlength.requiredLength+' characters';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''}; 
    }
    return !this._formErrors[field].valid;
  }

  initializeImage() {
    this.counter = 0;
    this.categoryModel = new Category();
    this.imageUrl = 'assets/img/default-image.png';
  }
}
