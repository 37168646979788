
export class school {
    id: number;
    title: string;
    name: string;
    email: string;
    image: string;
    phone:number;
	language:string;
    description:string;
  }
  