import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form.service';
import { school } from 'src/app/models/school';
import swal from 'sweetalert';
import { ServerResponse } from 'src/app/models/server-response.model';
import {isObject} from "util";
@Component({
  selector: 'app-edit-school',
  templateUrl: './edit-school.component.html',
  styleUrls: ['./edit-school.component.css']
})
export class EditSchoolComponent implements OnInit {

  _formErrors: any = {};
  form: FormGroup;
  imageUrl = 'assets/img/default-image.png';

  schoolModel = new school();
  fileToUpload: File = null;
  counter = 0;
  // width: number;
  // height: number;
  @ViewChild('title') titleField: ElementRef;
  @ViewChild('thumbnailFocus') thumbnailFocusField: ElementRef;

  constructor(private titlecase: TitleCasePipe, 
              private data: DataService, 
              private route: ActivatedRoute,
              private formService: FormService, 
              private router: Router) 
              {
                  this.route.params.subscribe(params => this.schoolModel.id = params.id);
              }
 
  ngOnInit() {
    this.data.getSchool(this.schoolModel.id).subscribe((data: ServerResponse) => {
      this.schoolModel = data.data;
      this.imageUrl =  this.schoolModel.image;
      this.imageUrl = this.imageUrl ;
      console.log('imageUrl', this.imageUrl);

    });
    this._resetFormErrors(); 
    this.form = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
	  language: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required,Validators.pattern('[0-9]+'),Validators.minLength(10)]),
      image: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
    });
    this.initializeImage();
  }

  private _resetFormErrors(): void {
    for (const credentialKey in this.schoolModel) {
      if (this.schoolModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }


  thumbnailhandleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    if (this.fileToUpload.size / 1024 / 1024 > 1) {
      swal({
        title: 'Message',
        text: 'File Size cannot be more than 1MB',
        icon: 'warning'
      });
    } else {
      // when file has loaded
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      swal({
        title: 'Please Wait...',
        text: 'Image is uploading....',
        closeOnClickOutside: false,
        icon: 'warning',
        timer: 1500 ,
        buttons: [
          false , false
        ]
      });
      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          // this.width = img.width;
          // this.height = img.height;
          // console.log('this.width', this.width);
          // console.log('this.height', this.height);
          // if (this.width === 180 && this.height === 180) {
            this.data.postFile(this.fileToUpload).subscribe((response: ServerResponse) => {
                console.log('response', response);
                if (response.error === 0 && response.authError === 0) {
                  this.imageUrl = SERVER_IMAGE_PATH  + response.data.fileUrl;
                  this.schoolModel.image = response.data.fileUrl;
                  swal({
                    title: 'Message',
                    text: 'Image has been Uploaded Successfully!',
                    closeOnClickOutside: false,
                    icon: 'success'
                  });
                  swal.stopLoading();
                  // swal.close();
                } else if (response.error > 0) {
                  if (isObject(response.msg)) {
                  } else {
                    this.form.get('img').setErrors({server: true, msg: response.msg});
                  }
                }
              },
              error => {
                console.log('error of file ', error.message);
              });
          // } else {
          //   swal({
          //     title: 'Message',
          //     text: 'Image must be of 180 X 180',
          //     icon: 'warning'
          //   });
          // }
        };
        img.src = event.target.result;
        this.schoolModel.image = img.src;
      };
    }
  }


  removethumbnailhandleFileInput() {
    this.imageUrl = 'assets/img/default-image.png';
    this.schoolModel.image = null;
    this.thumbnailFocusField.nativeElement.value  = '';

  }

  formReset() {
    this.router.navigate(['/' + Route.organizations]);
  }
  updateSchool() {
    if (!this.form.invalid) {
      this.urlReplace();
      this.schoolModel.email = this.schoolModel.email.toLowerCase();
      
      this.data.updateSchool(this.schoolModel).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Organization is Updated Successfully',
              closeOnClickOutside : false,
              icon: 'success'
            });
            this.router.navigate(['/' + Route.organizations]).catch(reason => {
              console.log('reason', reason);
              this.initializeImage();
              this.formReset();
            });
          } else if (response.error > 0) {
            if (isObject(response.msg)) {
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              // this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
            } 
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      if (this.hasError('title')) {
        this.titleField.nativeElement.focus();
      }  else if (this.imageUrl !== 'assets/img/default-image.png' && this.schoolModel.image == null) {
        this.thumbnailFocusField.nativeElement.focus();
      } else {
        console.log('error meassge ');
      }
      return false;
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      } if (this.form.get(field).errors.email) {
        msg = 'Email is invalid.';
      }
      if (this.form.get(field).errors.minlength) {
        msg = 'Phone number should not be less than 10 character';
      }
       if (this.form.get(field).errors.pattern) {
        //  if(field=='name')
        //  {
        //   msg =' Name is not valid use only alphabet' ;
        //  }
        if(field=='phone')
         {
          msg ='Phone is not valid use only number' ;
         }
        
      } 
      else if (this.form.get(field).errors.server) {
        msg = this.form.get(field).errors.msg;
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

  initializeImage() {
    this.counter = 0;
    this.schoolModel = new school();
    this.imageUrl = 'assets/img/default-image.png';
  }
  urlReplace() {
    this.schoolModel.image = this.schoolModel.image.replace(SERVER_IMAGE_PATH, '');
  }
}
