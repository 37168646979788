import { Component, OnInit } from '@angular/core';
import {Story} from '../../models/story';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {Category} from '../../models/category';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-category',
  templateUrl: './delete-category.component.html',
  styleUrls: ['./delete-category.component.css']
})
export class DeleteCategoryComponent implements OnInit {
   category$ = new Category();
  constructor(private data: DataService,private users: UserService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => this.category$.id = params.id);
  }
  ngOnInit() {
    // @ts-ignore
    if(this.users.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getCategory(this.category$.id).subscribe((data: ServerResponse) => {
      this.category$ = data.data;
      this.category$.img = SERVER_IMAGE_PATH +this.category$.img;
    });
  }
}
  deleteCategory(id) {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover Category!',
      icon: 'warning',
      buttons: [true , true],
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        this.data.deleteCategory({id: id}).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Category has been deleted Successfully!',
              icon: 'success'
            });
          }
          this.router.navigate(['/' + Route.categories]).catch(reason => {
            console.log('reason', reason);
          });
        });

      } else {
        swal('Category is safe!');
      }
    });
  }
  formReset() {
    this.router.navigate(['/' + Route.categories]);
  }
}
