import {Component, OnInit, ElementRef} from '@angular/core';
import {ROUTES} from '../../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';
import {Route} from '../../../utils/constant';
declare const $: any;
@Component({
  // moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  appRouts = Route;
  private listTitles: any[];
  location: Location;
  private toggleButton: any;
  private sidebarVisible: boolean;
  userSignInEmail: string;

  constructor(location: Location, private element: ElementRef, public user: UserService,
              public router: Router) {
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;


    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    console.log('navbar-toggle', navbar.getElementsByClassName('navbar-toggle')[0]);

    this.userSignInEmail = localStorage.getItem('userSignInEmail');
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    console.log('body ', body);
    // setTimeout(function () {
    //   toggleButton.classList.add('toggled');
    // }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose() {
    console.log('  sidebarClose()');
    const body = document.getElementsByTagName('body')[0];
    console.log('body ', body);
    // this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.split('/').pop();
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "";
  }

  logout() {
    this.user.logoutUser();
  }

  
isMobileMenu() {
  if ($(window).width() > 991) {
      return false;
  }
  return true;
}
}
