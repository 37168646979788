import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import {DataService} from "../../services/data.service";
import {ServerResponse} from "../../models/server-response.model";
import {Router} from '@angular/router';
import {Route} from '../../utils/constant';
import swal from 'sweetalert';

@Component({
  selector: 'app-btn-load-module-data',
  templateUrl: './btn-load-module-data.component.html',
  styleUrls: ['./btn-load-module-data.component.css']
})
export class BtnLoadModuleDataComponent implements OnInit {

  constructor(private userService: UserService, private dataService: DataService, private router: Router) {}

  ngOnInit() {}

  loadStaticModules() {
    this.dataService.loadStaticModule().subscribe((response: ServerResponse) => {
      if (response.error === 0 && response.authError === 0) {
        swal({
          title: 'Message',
          text: 'Module are loaded Successfully',
          icon: 'success'
        });
        this.router.navigate(['/' + Route.module]);
      } else{
        swal({
          title: 'Message',
          text: 'Module are not loaded',
          icon: 'warning'
        });
      }
    });
  }

}
