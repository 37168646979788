import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';
import {Route} from '../../../utils/constant';
declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test : Date = new Date();
  approut = Route;

  constructor(public user: UserService,
              public router: Router) {
  }

  ngOnInit() {
  }

  logout() {
    this.user.logoutUser();
  }

}
