import {Token} from './constant';
import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class ReqInterceptor implements HttpInterceptor {

  constructor() {
  } 
// changeit 
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const token = localStorage.getItem(Token.userToken);
     const token1 = localStorage.getItem(Token.webuserToken);
    let headers = req.headers;
    if (!req.headers.has('Content-Type')) {
      headers = req.headers.set('Content-Type', 'application/json')
    }
    let reqHeaders = {};
    if (token != null) {
      headers = req.headers.set('auth-token', token);
      // reqHeaders['auth-token'] = token;
    }
    if (token1 != null) {
      headers = req.headers.set('auth-token', token1);
      // reqHeaders['auth-token'] = token;
    }
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({headers: headers});

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
