import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {Story} from '../../models/story';
import swal from 'sweetalert';
import {Route} from '../../utils/constant';
import {ServerResponse} from '../../models/server-response.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-deletestory',
  templateUrl: './deletestory.component.html',
  styleUrls: ['./deletestory.component.scss']
})
export class DeletestoryComponent implements OnInit {

   story$ = new Story();
   categoryList: Array<any> ;

  constructor(private data: DataService,private user: UserService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => this.story$.id = params.id);
  }
  ngOnInit() {
    // @ts-ignore
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getStory(this.story$.id).subscribe((data: ServerResponse) => {
      this.story$ = data.data;
      console.log('story', this.story$);
      console.log('data',  data.data);
      // this.story$.thumbnail = this.story$.thumbnail.replace(SERVER_IMAGE_PATH, '');
    });
    this.getCategories();
    }
  }
  getCategories(){
    this.data.getCategories().subscribe(
      data => {
        if (!data) {
          return;
        }
        this.categoryList = data.data.categories;
      });
  }
  deleteStory(id) {
    console.log('in deleteStory()', id);
     swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover Story!',
      icon: 'warning',
      buttons: [true , true],
      dangerMode: true
    }).then((willDelete) => {
        if (willDelete) {
          this.data.deleteStory({id: id}).subscribe((response: ServerResponse) => {
            console.log('response', response);
            if (response.error === 0 && response.authError === 0) {
              swal({
                title: 'Message',
                text: 'Story has been deleted Successfully!',
                icon: 'success'
              });
            }
            this.router.navigate(['/' + Route.default]).catch(reason => {
              console.log('reason', reason);
            });
          });

        } else {
          swal('Story is safe!');
        }
      });
  }
  formReset() {
    this.router.navigate(['/' + Route.default]);
  }
}
