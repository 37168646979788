export class Page {
  index: number;
  image: string;
  content: string;
  type: string;
}

export const PAGE_TYPE = {
  TEXT_IMAGE: "TextImage",
  TEXT_IMAGE2S: "TextImage2S",
  TEXT_IMAGE3S: "TextImage3S",
  TEXT: "Text",
  IMAGE: "Image"
};
