export class recipt {
    data:{};
    // id:string;
    // country_code:string;
    // email_address:string;
    // given_name:string;
    // surname:string;
    // payer_id:string;
    // status:string;
    organization_id:string;
} 