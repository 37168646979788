import {AbstractControl, Validators} from '@angular/forms';

export class CustomValidators extends Validators {

  /**
   * Match two controls if they are the same
   * @param firstControlName
   * @param secondControlName
   * @returns {(AC: AbstractControl) => any}
   * @constructor
   */
  static Equal(firstControlName, secondControlName) {
    return (AC: AbstractControl) => {
      let firstControlValue = AC.get(firstControlName).value; // to get value in input tag
      let secondControlValue = AC.get(secondControlName).value; // to get value in input tag
      if (firstControlValue !== secondControlValue) {
        AC.get(secondControlName).setErrors({Equal: true});
        console.log(true);
      } else {
        AC.get(secondControlName).setErrors(null);
        console.log(null);
        return null;
      }
    };
  }

  static Equals(compare) {
    return (AC: AbstractControl) => {

      if (AC.value !== AC.get(compare).value) {
        console.log(true);
        return {Equal: true};
      } else {
        console.log(null);
        return null;
      }
    };
  }

}
