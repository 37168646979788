import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';
import {Route} from '../../../utils/constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  approut = Route;

  constructor(public user: UserService,
              public router: Router) {
  }

  ngOnInit() {
  }

  logout() {
    this.user.logoutUser();
  }

}
