import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {MatSort, MatTableModule, MatTableDataSource,MatPaginator} from '@angular/material';
import {DataService} from '../../services/data.service';
import {Route} from '../../utils/constant';
import swal from 'sweetalert';
import {ServerResponse} from '../../models/server-response.model';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare var $: any;


@Component({
  selector: 'app-stories',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.css']
})
export class UsersPageComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  
  credential = {
    endsub: '',
    appVersion : 'V2'
  };
  
  dataSource;
   displayColumns = ['id', 'name' , 'email', 'role', 'startSubscribe','endSubscribe','fromSubscribe','isSubscribe', 'isActive', 'permission', 'reset_password'];
  
  appRouts = Route;
  searchFor: string;
  
  
  form: FormGroup;
  form1: FormGroup;
  @ViewChild('endsub') endsubField: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
   pg: number;
   private currentId="";
   private currentIdTime="";
  
  constructor(private data: DataService, private router: Router,public user: UserService) {
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getUsers().subscribe(data => {
      if (data.error === 0 && data.authError === 0) {
        if (!data) {
          return;
        }
        this.dataSource = new MatTableDataSource(data.data.users);
		this.dataSource.paginator = this.paginator;
        // console.log('category',data.data.stories[0].category);
        this.dataSource.sort = this.sort;
      } else if (data.error === 1 && data.authError === 1) {
          localStorage.clear();
          this.router.navigate(['/' + Route.login]);
        }
      });
  }
  this.form1 = new FormGroup({
      endsub: new FormControl(this.credential.endsub, [Validators.required]),
      appVersion: new FormControl(this.credential.appVersion),
    });
	this.pg=1;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilter() {
    this.dataSource.filter = null;
    this.searchFor = '';
  }

  changeUTCdate(date){
	var options={year:'numeric',month:'numeric',day:'numeric',hour: '2-digit', minute:'2-digit'};
    var _utc = new Date(date).toLocaleString('en-US',options);
    return _utc;
  }
  
  getEndDate(id,endSubscribe){
	  this.currentId=id;
	  this.currentIdTime=endSubscribe;
	  var dtetime= String(new Date()).split(' ');
		var currdate= new Date();
		
		var edate = dtetime[3]+"-"+("0" + (currdate.getMonth() + 1)).slice(-2)+"-"+dtetime[2];
		
		if(endSubscribe){
			dtetime = endSubscribe.split(' ');
			
			edate = dtetime[0];
		}
		$('#EndSub').val(edate);
  }
  
  sendResetPassword(e, userId){
	this.data.postresetUserPassword(userId).subscribe((response: ServerResponse) => {
		if (response.error === 0 && response.authError === 0) {
		  swal({
			title: 'Message',
			text: 'Reset Password Link Sent Successfully',
			icon: 'success'
		  });
		} else{
		  swal({
			title: 'Message',
			text: 'Reset Password Link is not Sent',
			icon: 'warning'
		  });
		}
	  });
  }
  
	changeEndDate(e){
		
		var dtetime = this.currentIdTime.split(' ');
		var etime = dtetime[1];
		var edate = $('#EndSub').val();
		
		
		var enduscribe=edate+' '+etime;
				this.data.extendUserSubcription({id: this.currentId,endSub:enduscribe}).subscribe((response: ServerResponse) => {
					//console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User subcription date extended successfully',
						icon: 'success'
					  });
					  this.ngOnInit();
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in activating an user account.',
						icon: 'success'
					  });
					}
				  });
	}
		
  
  
	/*changeEndDate(e,usrobj){
		var dtetime=usrobj.endSubscribe.split(' ');
		var edate=dtetime[0];
		var etime=dtetime[1];
		swal({
			  title: "Are you sure?",
			  content: {
				element: "input",
				attributes: {
				  defaultValue: edate,
				  id : "endSub",
				  type: "date",
				  
				},
			  },
			  icon: "warning",
			  buttons: [
					"Cancel",
					"OK"
				],
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				  var enduscribe=willDelete+' '+etime;
				this.data.extendUserSubcription({id: usrobj.id,endSub:enduscribe}).subscribe((response: ServerResponse) => {
					//console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User subcription date extended successfully',
						icon: 'success'
					  });
					  this.ngOnInit();
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in activating an user account.',
						icon: 'success'
					  });
					}
				  });
			  }
			  else{
				  console.log("cancel");
			  }
			});
	}*/

  changeStatus(e, id) {
    console.log('id' , id);
	if (e.target.checked) {
		swal({
			  title: "Are you sure?",
			  text: "Activate this user's account",
			  icon: "warning",
			  buttons: [
					"Cancel",
					"OK"
				],
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				this.data.activeUser({id: id}).subscribe((response: ServerResponse) => {
					console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User account successfully',
						icon: 'success'
					  });
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in activating an user account.',
						icon: 'success'
					  });
					  e.target.checked=false;
					}
				  });
			  } 
			  else{
				  e.target.checked=false;
			  }
			});
	}
	else{
		swal({
			  title: "Are you sure?",
			  text: "Deactivate this user's account",
			  icon: "warning",
			  buttons: ["Cancel","Ok"],
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
			      this.data.deactiveUser({id: id}).subscribe((response: ServerResponse) => {
					console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User account successfully',
						icon: 'success'
					  });
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in activating an user account.',
						icon: 'success'
					  });
					  e.target.checked=true;
					}
				  });
			  } 
			  else{
				  e.target.checked=true;
			  }
			});
		
	}
    /*if (e.target.checked) {
      this.data.publishStory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Story is Published Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in Publishing a story',
            icon: 'success'
          });
        }
      });
    } else {
      this.data.unPublishStory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Story is UnPublished Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in UnPublishing a Story',
            icon: 'success'
          });
        }
      });
    }*/
  }

  activeCancelSubscription(e, id) {
    console.log('id' , id);
	if (e.target.checked) {
		swal({
			  title: "Are you sure?",
			  text: "Activate this user's subscription?",
			  icon: "warning",
			  buttons: [
					"Cancel",
					"OK"
				],
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				this.data.activeUserSubcription({id: id}).subscribe((response: ServerResponse) => {
					console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User\'s Subscription has been activated successfully',
						icon: 'success'
					  });
					  
					} else if (response.error > 0) {
						
					  swal({
						title: 'Message',
						text: String(response.msg),
						icon: 'warning'
					  });
					  e.target.checked=false;
					}
				  });
			  } 
			  else{
				  e.target.checked=false;
			  }
			});
	}
	else{
		swal({
			  title: "Are you sure?",
			  text: "Deactivate this user's subscription?",
			  icon: "warning",
			  buttons: ["Cancel","Ok"],
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				  
			      this.data.cancelUserSubcription({id: id}).subscribe((response: ServerResponse) => {
					console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User\'s Subscription has been deactivated successfully',
						icon: 'success'
					  });
					  e.target.checked=false;
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in deactiviting an user\'s subscription.',
						icon: 'success'
					  });
					  e.target.checked=true;
					}
				  }); 
			  } 
			  else{
				  e.target.checked=true;
			  }
			});
		
	}
  }



}

