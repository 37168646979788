import {Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TitleCasePipe} from '@angular/common';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../services/form.service';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {Route} from '../../utils/constant';
import {isObject} from 'util';
import {userrole} from '../../models/userrole';
import { UserService } from 'src/app/services/user.service';
import {Observable} from "rxjs";


@Component({
  selector: 'app-edit-userrole',
  templateUrl: './edit-userrole.component.html',
  styleUrls: ['./edit-userrole.component.css']
})

export class EditUserRoleComponent implements OnInit {
  _formErrors: any = {};
  form: FormGroup;
  userRoleModel = new userrole();
  user_modules: Observable<any>;
  counter = 0;

  @ViewChild('title') titleField: ElementRef;
  @ViewChild('description') descriptionField: ElementRef;
  @ViewChild('active') activeField: ElementRef;
  @ViewChild('modules') modulesField: ElementRef;

  constructor(private titlecase: TitleCasePipe,private users: UserService, 
              private data: DataService, private route: ActivatedRoute,
              private formService: FormService, private router: Router, 
              private readonly changeDetectorRef: ChangeDetectorRef, 
              private formBuilder: FormBuilder) {
    this.route.params.subscribe(params => this.userRoleModel.id = params.id);
  }

  // ngAfterViewChecked(): void {
  //   this.changeDetectorRef.detectChanges();
  // }

  ngOnInit() {
    // @ts-ignore
    if(this.users.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
      this.getModules();
      this.data.getUserRole(this.userRoleModel.id).subscribe((data: ServerResponse) => {
        let resp_data = data.data;
        const updatedModules = resp_data.modules.map((moduleObject, idx) => ({
          ...moduleObject,
          index: idx,
          read_perm: moduleObject.read_perm ? true : false, 
          create_perm: moduleObject.create_perm ? true : false,
          write_perm: moduleObject.write_perm ? true : false, 
          delete_perm: moduleObject.delete_perm ? true : false,
          moduleId: typeof moduleObject.moduleId === 'object' ? moduleObject.moduleId.id : moduleObject.moduleId,
          moduleName: typeof moduleObject.moduleId === 'object' ? moduleObject.moduleId.title : moduleObject.moduleName
        }));

        resp_data.modules = updatedModules;
        this.userRoleModel = resp_data;
      });
      this._resetFormErrors();
      this.form = new FormGroup({
        title: new FormControl(null, [Validators.required]),
        active: new FormControl(null, []),
        description: new FormControl(null, []),
        modules: this.formBuilder.array([ this.createModulePermissionFormGroup() ])
      });
      // this.initializeImage();
    }
  }

  get modulesFormArray(): FormArray {
    return this.form.get('modules') as FormArray;
  }

  createModulePermissionFormGroup(): FormGroup {
    return this.formBuilder.group({
      moduleId: '',
      read_perm: false,
      create_perm: false,
      write_perm: false,
      delete_perm: false
    });
  }
 
  addRow() {
    const modulePermission = this.formBuilder.group({
      moduleId: '',
      read_perm: false,
      create_perm: false,
      write_perm: false,
      delete_perm: false
    });

    this.userRoleModel.modules.push(modulePermission.value);
    const modulesFormArray = this.form.get('modules') as FormArray;
    modulesFormArray.push(modulePermission);
  }

  trackByModuleId(index: number, module: any): string {
    return module.moduleId;
  }

  deleteRow(i) {
    this.userRoleModel.modules.splice(i, 1);
  }

  getModules(){
    this.data.getModules().subscribe((response: ServerResponse) => {
      console.log('response', response);
      if (response.error === 0 && response.authError === 0) {
        this.user_modules = response.data.moduleList;
      }
    },
    error => {
      console.log('error meassge ', error.message);
    });
  }

  private _resetFormErrors(): void {
    for (const credentialKey in this.userRoleModel) {
      if (this.userRoleModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }

  formReset() {
    this.router.navigate(['/' + Route.userrole]);
  }

  updateUserRole() {
    // const modulesFormArray = this.form.get('modules') as FormArray;
    // const modulesArrayNonFilter = modulesFormArray.value;
    // const modulesArrayNonFilterDef = this.userRoleModel.modules;
    // const modulesArray = [];

    // let empty_counter = 0, is_duplicate = false;
    // let actualModuleArray = modulesArrayNonFilter;
    // actualModuleArray.concat(modulesArrayNonFilterDef);
    
    // actualModuleArray.forEach((moduleObject, index) => {
    //   if (moduleObject.moduleId.length == 0){
    //     empty_counter += 1;
    //   } else{
    //     modulesArray.push(moduleObject);
    //   }
    // });

    // is_duplicate = actualModuleArray.some((item, index) => {
    //   return actualModuleArray.findIndex((otherItem) => otherItem.moduleId === item.moduleId && index !== actualModuleArray.indexOf(otherItem)) !== -1;
    // });

    // if (empty_counter > 1){
    //   swal({
    //     title: 'Message',
    //     text: 'Oops, empty module is not allowed, please select module',
    //     closeOnClickOutside : false,
    //     icon: 'warning'
    //   });
    // }
    // else if (is_duplicate){
    //   swal({
    //     title: 'Message',
    //     text: "Oops, duplicate module lines are found, please remove duplicate module's line",
    //     closeOnClickOutside : false,
    //     icon: 'warning'
    //   });
    // } else{
    //   const modulesArrayUpdated = modulesArray.map((moduleObject) => ({
    //     ...moduleObject,
    //     read_perm: moduleObject.read_perm ? 1 : 0, create_perm: moduleObject.create_perm ? 1 : 0,
    //     write_perm: moduleObject.write_perm ? 1 : 0, delete_perm: moduleObject.delete_perm ? 1 : 0
    //   }));
    //   this.userRoleModel.modules = modulesArrayUpdated;
    //   let moduleModel = this.userRoleModel;

    //   if (!this.form.invalid) {
    //     this.data.updateUserRole(this.userRoleModel).subscribe((response: ServerResponse) => {
    //         console.log('response', response);
    //         if (response.error === 0 && response.authError === 0) {
    //           swal({
    //             title: 'Message',
    //             text: 'UserRole is Updated Successfully',
    //             closeOnClickOutside : false,
    //             icon: 'success'
    //           });
    //           this.router.navigate(['/' + Route.userrole]).catch(reason => {
    //             console.log('reason', reason);
    //             this.formReset();
    //           });
    //         } else if (response.error > 0) {
    //           if (isObject(response.msg)) {
    //             for (const msgKey in response.msg) {
    //               if (response.msg.hasOwnProperty(msgKey)) {
    //                 this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
    //               }
    //             }
    //           } else {
    //             // this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
    //           }
    //         }
    //       },
    //       error => {
    //         console.log('error meassge ', error.message);
    //       });
    //   } else {
    //     this.formService.validateAllFormFields(this.form);
    //     if (this.hasError('title')) {
    //       this.titleField.nativeElement.focus();
    //     } else {
    //       console.log('error meassge ');
    //     }
    //     return false;
    //   }
    // }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

}
