import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator} from '@angular/material';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {DataService} from '../../services/data.service';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {


  @ViewChild(MatSort) sort: MatSort;
   @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource;
  displayColumns = ['id', 'title', 'image','isActive', 'action'];
  appRouts = Route;
  searchFor: string;
  SERVER_IMAGE_PATH= SERVER_IMAGE_PATH;
  constructor(private data: DataService, private user: UserService, private router: Router) {}

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getCategories().subscribe(data => {
      if (data.error === 0 && data.authError === 0) {
        if (!data) {
          return;
        }
        this.dataSource = new MatTableDataSource(data.data.categories);
		this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else if (data.error === 1 && data.authError === 1) {
        localStorage.clear();
        this.router.navigate(['/' + Route.login]);
      }
      });
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilter() {
    this.dataSource.filter = null;
    this.searchFor = '';
  }

  changeStatus(e, id) {
    console.log('id' , id);
    if (e.target.checked) {
      this.data.publishCategory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Category is Published Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in Publishing a Category',
            icon: 'success'
          });
        }
      });
    } else {
      this.data.unPublishCategory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Category is UnPublished Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in UnPublishing a Category',
            icon: 'success'
          });
        }
      });
    }
  }

}
