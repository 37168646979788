import { Component, OnInit } from '@angular/core';
import {Story} from '../../models/story';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ServerResponse} from '../../models/server-response.model';
import swal from 'sweetalert';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {school} from '../../models/school';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-delete-school',
  templateUrl: './delete-school.component.html',
  styleUrls: ['./delete-school.component.css']
})
export class DeleteSchoolComponent implements OnInit {
  school$ = new school();
  constructor(private data: DataService,private users: UserService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => this.school$.id = params.id);
  }
  
  ngOnInit() {
    this.data.getSchool(this.school$.id).subscribe((data: ServerResponse) => {
      this.school$ = data.data;
      this.school$.image =this.school$.image;
  });
  }

  deleteSchool(id) {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover Organization!',
      icon: 'warning',
      buttons: [true , true],
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        this.data.deleteSchool({id: id}).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'Organization has been deleted Successfully!',
              icon: 'success'
            });
          }
          this.router.navigate(['/' + Route.organizations]).catch(reason => {
            console.log('reason', reason);
          });
        });

      } else {
        swal('Organization is safe!');
      }
    });
  }
  formReset() {
    this.router.navigate(['/' + Route.organizations]);
  }
}
