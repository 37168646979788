import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, MatTableModule, MatTableDataSource, MatPaginator} from '@angular/material';
import {DataService} from '../../services/data.service';
import {Route} from '../../utils/constant';
import swal from 'sweetalert';
import {ServerResponse} from '../../models/server-response.model';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.css']
})
export class StoriesComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource;
  displayColumns = ['id' , 'subscriptionType', 'title', 'isActive', 'action','category'];
  appRouts = Route;
  searchFor: string;

  constructor(private data: DataService, private router: Router,public user: UserService) {
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getStories().subscribe(data => {
      if (data.error === 0 && data.authError === 0) {
        if (!data) {
          return;
        }
        for (const item of data.data.stories) {
          if (item.subscriptionType === 2) {
            item.subscriptionType = 'Featured';
          } else if (item.subscriptionType === 1) {
            item.subscriptionType = 'Free';
          } else if (item.subscriptionType === 3) {
            item.subscriptionType = 'Premium';
          }
        }
        this.dataSource = new MatTableDataSource(data.data.stories);
        // console.log('category',data.data.stories[0].category);
		this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else if (data.error === 1 && data.authError === 1) {
          localStorage.clear();
          this.router.navigate(['/' + Route.login]);
        }
      });
  }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilter() {
    this.dataSource.filter = null;
    this.searchFor = '';
  }

  changeStatus(e, id) {
    console.log('id' , id);
    if (e.target.checked) {
      this.data.publishStory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Story is Published Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in Publishing a story',
            icon: 'success'
          });
        }
      });
    } else {
      this.data.unPublishStory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Story is UnPublished Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in UnPublishing a Story',
            icon: 'success'
          });
        }
      });
    }
  }

}

