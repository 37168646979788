import {Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TitleCasePipe} from '@angular/common';
import {DataService} from '../../services/data.service';
import {FormService} from '../../services/form.service';
import swal from 'sweetalert';
import {ServerResponse} from '../../models/server-response.model';
import {isObject} from "util";
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {ActivatedRoute, Router} from '@angular/router';
import { userrole } from '../../models/userrole';
import { UserService } from 'src/app/services/user.service';
import {Observable} from "rxjs";

@Component({
  selector: 'app-add-userrole',
  templateUrl: './add-userrole.component.html',
  styleUrls: ['./add-userrole.component.css']
})
export class AddUserRoleComponent implements OnInit {

  _formErrors: any = {};
  form: FormGroup;
  userRoleModel = new userrole();
  user_modules: Observable<any>;
  counter = 0;

  @ViewChild('title') titleField: ElementRef;
  @ViewChild('description') descriptionField: ElementRef;
  @ViewChild('active') activeField: ElementRef;
  @ViewChild('modules') modulesField: ElementRef;

  constructor(
    private titlecase: TitleCasePipe, private data: DataService, private user: UserService, 
    private route: ActivatedRoute, private formService: FormService, private router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder
    ) {
    this.route.params.subscribe(params => this.userRoleModel.id = params.id);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
      this.getModules();
      this._resetFormErrors();
      this.form = new FormGroup({
        title: new FormControl(null, [Validators.required]),
        active: new FormControl(null, []),
        description: new FormControl(null, []),
        modules: this.formBuilder.array([ this.createModulePermissionFormGroup() ])
      });
      // this.userRoleModel = new userrole();
    }
  }

  createModulePermissionFormGroup(): FormGroup {
    return this.formBuilder.group({
      moduleId: '',
      read_perm: false,
      create_perm: false,
      write_perm: false,
      delete_perm: false
    });
  }
 
  addRow() {
    debugger
    const modulePermission = this.formBuilder.group({
      moduleId: [''],
      read_perm: [false],
      create_perm: [false],
      write_perm: [false],
      delete_perm: [false]
    });
    
    this.userRoleModel.modules.push(modulePermission.value); 
    const modulesFormArray = this.form.get('modules') as FormArray;
    modulesFormArray.push(modulePermission);
  }


  deleteRow(i) {
    this.userRoleModel.modules.splice(i, 1);
  }

  getModules(){
    this.data.getModules().subscribe((response: ServerResponse) => {
      console.log('response', response);
      if (response.error === 0 && response.authError === 0) {
        this.user_modules = response.data.moduleList;
      }
    },
    error => {
      console.log('error meassge ', error.message);
    });
  }

  private _resetFormErrors(): void {
    for (const credentialKey in this.userRoleModel) {
      if (this.userRoleModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }

  formReset() {
    this.router.navigate(['/' + Route.userrole]);
  }

  OnSubmit() {
    const modulesFormArray = this.form.get('modules') as FormArray;
    const modulesArrayNonFilter = modulesFormArray.value;
    const modulesArrayNonFilterDef = this.userRoleModel.modules;
    const modulesArray = [];
    
    let empty_counter = 0, is_duplicate = false;
    let actualModuleArray = modulesArrayNonFilterDef;
    actualModuleArray.concat(modulesArrayNonFilter);

    actualModuleArray.forEach((moduleObject, index) => {
      if (moduleObject.moduleId.length == 0){
        empty_counter += 1;
      } else{
        modulesArray.push(moduleObject);
      }
    });

    is_duplicate = actualModuleArray.some((item, index) => {
      return actualModuleArray.findIndex((otherItem) => otherItem.moduleId === item.moduleId && index !== actualModuleArray.indexOf(otherItem)) !== -1;
    });

    if (empty_counter > 1){
      swal({
        title: 'Message',
        text: 'Oops, empty module is not allowed, please select module',
        closeOnClickOutside : false,
        icon: 'warning'
      });
    }
    else if (is_duplicate){
      swal({
        title: 'Message',
        text: "Oops, duplicate module lines are found, please remove duplicate module's line",
        closeOnClickOutside : false,
        icon: 'warning'
      });
    } else{
      const modulesArrayUpdated = modulesArray.map((moduleObject) => ({
        ...moduleObject,
        read_perm: moduleObject.read_perm ? 1 : 0, create_perm: moduleObject.create_perm ? 1 : 0,
        write_perm: moduleObject.write_perm ? 1 : 0, delete_perm: moduleObject.delete_perm ? 1 : 0
      }));
      this.userRoleModel.modules = modulesArrayUpdated;
      if (!this.form.invalid) {
        this.data.postUserRole(this.userRoleModel).subscribe((response: ServerResponse) => {
            console.log('response', response);
            if (response.error === 0 && response.authError === 0) {
              swal({
                title: 'Message',
                text: 'UserRole is Added Successfully',
                icon: 'success'
              });
              this.formReset();
              this.router.navigate(['/' + Route.userrole]).catch(reason => {
                console.log('reason', reason);
              });
            } else if (response.error > 0) {
              if (isObject(response.msg)) {
                for (const msgKey in response.msg) {
                  if (response.msg.hasOwnProperty(msgKey)) {
                    this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                  }
                }
              } else {
                // this.form.get('title').setErrors({server: true, msg: response.msg});
              }
            }
          },
          error => {
            console.log('error meassge ', error.message);
          });
      } else {
        this.formService.validateAllFormFields(this.form);
        if (this.hasError('title')) {
          this.titleField.nativeElement.focus();
        }  
        else {
          console.log('error meassge '); 
        }

        return false;
      }
    }
  }

  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''}; 
    }
    return !this._formErrors[field].valid;
  }

}
