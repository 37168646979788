import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import {ServerResponse} from "../../models/server-response.model";
import {USER_DATA_FILE_PATH} from "../../utils/constant";

@Component({
  selector: 'app-btn-download-sale-data',
  templateUrl: './btn-download-sale-data.component.html',
  styleUrls: ['./btn-download-sale-data.component.css']
})
export class BtnDownloadSaleDataComponent implements OnInit {

  fileLink;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.downloadSaleDataFile()
  }

  downloadSaleDataFile() {
    this.userService.getSalesData().subscribe((response: ServerResponse) => {
      if (response.error === 0 && response.authError === 0) {
        this.fileLink = USER_DATA_FILE_PATH+response.data.fileUrl;
      }
    });
  }

}
