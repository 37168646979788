import {ModulePermission} from './user-module-permission';


export class userrole {
  id: number;
  title: string;
  active: number;
  description: string;
  modules: ModulePermission[];
}

