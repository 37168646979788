
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, MatTableModule, MatTableDataSource, MatPaginator} from '@angular/material';
import {DataService} from '../../services/data.service';
import {Route} from '../../utils/constant';
import swal from 'sweetalert';
import {ServerResponse} from '../../models/server-response.model';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-stories',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
   @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource;
  displayColumns = ['id', 'title' , 'description','type','time','created_at', 'action'];
  appRouts = Route;
  searchFor: string;

  constructor(private data: DataService, private router: Router,public user: UserService) {
  }

  ngOnInit() {
    if(this.user.getRole())
    {
      this.router.navigate(['/' + Route.organizations])
    }
    else{
    this.data.getAnnouncements().subscribe(data => {
      if (data.error === 0 && data.authError === 0) {
        if (!data) {
          return;
        }
	
        this.dataSource = new MatTableDataSource(data.data.announcementList);
		this.dataSource.paginator = this.paginator;
        // console.log('category',data.data.stories[0].category);
        this.dataSource.sort = this.sort;
      } else if (data.error === 1 && data.authError === 1) {
          localStorage.clear();
          this.router.navigate(['/' + Route.login]);
        }
      });
  }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilter() {
    this.dataSource.filter = null;
    this.searchFor = '';
  }
  
  changeUTCdate(date){
	var options={year:'numeric',month:'numeric',day:'numeric',hour: '2-digit', minute:'2-digit'};
    var _utc = new Date(date).toLocaleString('en-US', options);
    return _utc;
  }
  
  deleteAnnc(e,id){
	  
	  this.data.deleteAnnouncement({id: id}).subscribe((response: ServerResponse) => {
					console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'Announcement deleted successfully',
						icon: 'success'
					  });
					  this.router.navigate(['/' + Route.announcement]);
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in deleting an announcement.',
						icon: 'success'
					  });
					}
	 });
  }

  changeStatus(e, id) {
    console.log('id' , id);
	/*if (e.target.checked) {
		swal({
			  title: "Are you sure?",
			  text: "Activate this user's account",
			  icon: "warning",
			  buttons: [
					"Cancel",
					"OK"
				],
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				this.data.activeUser({id: id}).subscribe((response: ServerResponse) => {
					console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User account successfully',
						icon: 'success'
					  });
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in activating an user account.',
						icon: 'success'
					  });
					}
				  });
			  } 
			  else{
				  e.target.checked=false;
			  }
			});
	}
	else{
		swal({
			  title: "Are you sure?",
			  text: "Deactivate this user's account",
			  icon: "warning",
			  buttons: ["Cancel","Ok"],
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
			      this.data.deactiveUser({id: id}).subscribe((response: ServerResponse) => {
					console.log('response', response);
					if (response.error === 0 && response.authError === 0) {
					  swal({
						title: 'Message',
						text: 'User account successfully',
						icon: 'success'
					  });
					} else if (response.error > 0) {
					  swal({
						title: 'Message',
						text: 'There is problem in activating an user account.',
						icon: 'success'
					  });
					}
				  });
			  } 
			  else{
				  e.target.checked=true;
			  }
			});
		
	}*/
    /*if (e.target.checked) {
      this.data.publishStory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Story is Published Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in Publishing a story',
            icon: 'success'
          });
        }
      });
    } else {
      this.data.unPublishStory({id: id}).subscribe((response: ServerResponse) => {
        console.log('response', response);
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'Story is UnPublished Successfully',
            icon: 'success'
          });
        } else if (response.error > 0) {
          swal({
            title: 'Message',
            text: 'There is problem in UnPublishing a Story',
            icon: 'success'
          });
        }
      });
    }*/
  }

}

