import {Token, Route} from './constant';
import {HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';


export class Utils {

  public static objectToJson(obj: any) {
    const dataStr = JSON.stringify(obj);
    return dataStr;
  }

  public static jsonToObject(json: any) {
    const dataObj = JSON.parse(json);
    return dataObj;
  }
// changeit  
  public static requestHeaders() {
    let token = localStorage.getItem(Token.userToken);
    token = localStorage.getItem(Token.webuserToken);
    if (token != null) {
      return new HttpHeaders({'Content-Type': 'application/json', 'auth-token': token});
    } else {
      return new HttpHeaders({'Content-Type': 'application/json'});
    }
  }

  public static analyseResponse(response: any) {
    let router: Router;

    if (response.authError) {
      localStorage.removeItem(Token.userToken);
      router.navigate(['/' + Route.login]);
    }
  }

}
