
export class announcement {
    id: number;
    title: string;
    description: string;
    ntfnType:string;
	ntfnTime:Date;
	isSent:number;
	created_at:string;
	noOfSent:number;
    editMode:number;
    isExpire:number;
}
  